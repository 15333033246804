import { defineStore } from "pinia";
import { ref, toRef } from "vue";
import { useGraphqlClient, useCoreApi } from "@core/app-rest-client";
import { useSyncState } from "@core/sync-state";
import { getApiSettings, Theme } from "@leon-hub/api-sdk";
import { isTheme } from "@leon-hub/api-guards";
import { isEmpty } from "@leon-hub/guards";
import { useLocalStorageManager } from "@leon-hub/local-storage";
import { getBuiltinConfig } from "@leon-hub/service-locator-env";
import { useSiteConfigStore } from "@core/site-config";
import { getCustomerTimezoneOffset, DateTimeInterval } from "@leon-hub/utils";
var StorageKeys = /* @__PURE__ */ ((StorageKeys2) => {
  StorageKeys2["THEME"] = "theme";
  StorageKeys2["FIRST_THEME"] = "firstTheme";
  StorageKeys2["IS_AUTO_THEME"] = "isAutoTheme";
  return StorageKeys2;
})(StorageKeys || {});
function fetchApiSettings() {
  return getApiSettings(useGraphqlClient(), (node) => node.queries.system.apiSettings.getApiSettings, void 0, {
    silent: true
  });
}
const useApiSettingsStore = /* @__PURE__ */ defineStore("api-settings", () => {
  const api = useGraphqlClient();
  const graphqlBatchRequestsMaxSize = ref(0);
  function handleApiSettings(settings) {
    if (settings && graphqlBatchRequestsMaxSize.value !== settings.graphqlBatchRequestsMaxSize) {
      graphqlBatchRequestsMaxSize.value = settings.graphqlBatchRequestsMaxSize;
      api.setMaxBatchQueueSize(graphqlBatchRequestsMaxSize.value);
    }
  }
  async function updateApiSettings() {
    handleApiSettings(await fetchApiSettings());
  }
  useSyncState(updateApiSettings, "api-settings");
  return {
    graphqlBatchRequestsMaxSize,
    handleApiSettings,
    updateApiSettings
  };
});
const useInitAppConfigStore = /* @__PURE__ */ defineStore("init-app-config", () => {
  var _a, _b, _c, _d, _e, _f;
  const themeConfig = ref({
    default: Theme.DARK,
    isAuto: false
  });
  const localStorageManager = useLocalStorageManager();
  if (process.env.VUE_APP_PLATFORM_CORDOVA) {
    const siteConfigStore = useSiteConfigStore();
    const themes = toRef(siteConfigStore, "themes");
    const { id: defaultTheme } = themes.value.find((theme) => theme.isDefault) ?? {};
    themeConfig.value.default = defaultTheme ?? (process.env.VUE_APP_STYLE_SLOTT ? Theme.LIGHT : Theme.DARK);
    themeConfig.value.first = (_a = localStorageManager.getItem(StorageKeys.FIRST_THEME)) == null ? void 0 : _a.toUpperCase();
    themeConfig.value.current = (_b = localStorageManager.getItem(StorageKeys.THEME)) == null ? void 0 : _b.toUpperCase();
    themeConfig.value.isAuto = isEmpty(localStorageManager.getItem(StorageKeys.IS_AUTO_THEME)) ? false : localStorageManager.getItem(StorageKeys.IS_AUTO_THEME) === "true";
  } else {
    const initConfig = getBuiltinConfig();
    const defaultTheme = initConfig == null ? void 0 : initConfig.theme.default.toUpperCase();
    if (isTheme(defaultTheme)) {
      themeConfig.value.default = defaultTheme;
    }
    themeConfig.value.first = ((_c = initConfig == null ? void 0 : initConfig.theme.first) == null ? void 0 : _c.toUpperCase()) || ((_d = localStorageManager.getItem(StorageKeys.FIRST_THEME)) == null ? void 0 : _d.toUpperCase());
    if (process.env.NODE_ENV === "production") {
      themeConfig.value.current = localStorageManager.getItem(StorageKeys.THEME) || ((_e = initConfig == null ? void 0 : initConfig.theme.current) == null ? void 0 : _e.toUpperCase()) || themeConfig.value.default;
    } else {
      themeConfig.value.current = ((_f = localStorageManager.getItem(StorageKeys.THEME)) == null ? void 0 : _f.toUpperCase()) || themeConfig.value.default;
    }
    themeConfig.value.isAuto = isEmpty(localStorageManager.getItem(StorageKeys.IS_AUTO_THEME)) ? false : localStorageManager.getItem(StorageKeys.IS_AUTO_THEME) === "true";
  }
  return {
    themeConfig
  };
});
const _ServerDate = class _ServerDate {
  static setServerTime(timestamp) {
    _ServerDate.timeShift = timestamp - Date.now();
  }
  static now() {
    return Date.now() + _ServerDate.timeShift;
  }
  /**
   * Get customer's timezone offset for system time rounded to 0.5
   * (For any customer's timezone we must get same offset for same time)
   * For example:
   * Actual time is 12.00 at GMT+3
   * User can select any timezone in system settings but
   * If he has time set to 19.00 we got +9 because right now 19.00 in Tokyo (GMT+9)
   * Same for 01.00 we got -9 because right now in Anchorage 01.00 (GMT-9)
   * And we got +10.30 for time 20.30 same to Adelaide (GTM+10.30)
   */
  static getCustomerTimezoneOffset() {
    return getCustomerTimezoneOffset(_ServerDate.timeShift, (/* @__PURE__ */ new Date()).getTimezoneOffset());
  }
  static getTimeShift() {
    return _ServerDate.timeShift;
  }
};
_ServerDate.timeShift = 0;
let ServerDate = _ServerDate;
const useSyncDateTimeStore = /* @__PURE__ */ defineStore("sync-date-time", () => {
  function setServerTime(time) {
    ServerDate.setServerTime(time);
  }
  async function syncServerTime() {
    const result = await useCoreApi().syncServerTime();
    setServerTime(result);
  }
  useSyncState(syncServerTime, "sync-date-time");
  return {
    setServerTime,
    syncServerTime
  };
});
class ServerDateTimeInterval extends DateTimeInterval {
  static getNow() {
    return ServerDate.now();
  }
}
export {
  ServerDate,
  ServerDateTimeInterval,
  StorageKeys,
  fetchApiSettings,
  useApiSettingsStore,
  useInitAppConfigStore,
  useSyncDateTimeStore
};
