import { ref, toRef, computed, watch } from "vue";
import { useRouter } from "vue-router";
import { defineStore } from "pinia";
import { getRoutingConfigs, HomePageType } from "@leon-hub/api-sdk";
import { CustomerRouteAccessRole } from "@leon-hub/routing-config";
import { getLocationOrigin, getLocationHref, getLocationPath, getLocationSearch } from "@leon-hub/service-locator-env";
import { Events } from "@leon-hub/yandex-metrika";
import { useGoogleAnalytics, useAnalyticsCommonDataStore } from "@core/analytics";
import { useSiteConfigStore } from "@core/site-config";
import { useGraphqlClient } from "@core/app-rest-client";
import { stripPrerenderParametersFromUrl } from "@leon-hub/query-manager";
function fetchRoutingConfigs({ silent } = {}) {
  const apiClient = useGraphqlClient();
  return getRoutingConfigs(
    apiClient,
    (node) => node.queries.system.getRouting,
    {},
    { silent }
  );
}
function getFullUrlFromPath(uri) {
  if (!uri) {
    return "";
  }
  if (uri.startsWith("http://") || uri.startsWith("https://")) {
    return uri;
  }
  let origin = getLocationOrigin();
  if (!uri.startsWith("/")) {
    origin += "/";
  }
  return `${origin}${uri}`;
}
const useRouterStore = /* @__PURE__ */ defineStore("router", () => {
  const { pushGTM } = useGoogleAnalytics();
  const seoConfigs = ref({});
  const isBackButtonInTopBar = ref(false);
  const currentRoute = ref(null);
  const previousRoute = ref(null);
  const currentRouteIndex = ref(0);
  const lastNoModalRouteIndex = ref(0);
  const realCurrentRouteName = ref("");
  const previousRouteName = ref(void 0);
  const nextRouteName = ref(void 0);
  const previousRouteUrl = ref(void 0);
  const lastAnonymousRouteIndex = ref(0);
  const lastAuthorizedRouteIndex = ref(0);
  const isPopStateDetected = ref(false);
  const isRouterInit = ref(false);
  const currentRouteFullUrl = ref(getLocationHref());
  const siteConfigStore = useSiteConfigStore();
  const homePageType = toRef(siteConfigStore, "homePageType");
  const isSportHomePageType = computed(() => !!process.env.VUE_APP_FEATURE_SPORTLINE_ENABLED && homePageType.value === HomePageType.SPORTS);
  const isEGSHomePageType = computed(() => !!process.env.VUE_APP_FEATURE_CASINO_ENABLED && (homePageType.value === HomePageType.SLOTS || homePageType.value === HomePageType.LIVE_GAMES));
  const currentRouteName = computed(() => {
    var _a;
    return (_a = currentRoute.value) == null ? void 0 : _a.name;
  });
  const isCurrentRouteHasTopBar = computed(() => {
    var _a;
    return !!((_a = currentRoute.value) == null ? void 0 : _a.meta.hasTopBar);
  });
  async function updateAppRouteConfigs(silent = false) {
    handleAppRouteConfigs(await fetchRoutingConfigs({ silent }));
  }
  function handleAppRouteConfigs(result) {
    const { routing } = result;
    const config = JSON.parse(routing);
    seoConfigs.value = config.reduce((accumulator, item) => {
      accumulator[item.name] = {
        path: item.path,
        metaInfo: item.metaInfo,
        seoKey: item.seoKey,
        seoData: item.seoData
      };
      return accumulator;
    }, {});
  }
  function setBackButtonInTopBar(value) {
    isBackButtonInTopBar.value = value;
  }
  function updateIndexes(to) {
    var _a, _b;
    if (!to.meta.access || to.meta.access === CustomerRouteAccessRole.ANONYMOUS) {
      setLastAnonymousRouteIndex(((_a = window.history.state) == null ? void 0 : _a.position) || 0);
    }
    if (!to.meta.access || to.meta.access === CustomerRouteAccessRole.AUTHORIZED) {
      setLastAuthorizedRouteIndex(((_b = window.history.state) == null ? void 0 : _b.position) || 0);
    }
  }
  function setCurrentRoute(to, href) {
    previousRoute.value = currentRoute.value;
    currentRoute.value = to;
    currentRouteFullUrl.value = getFullUrlFromPath(href);
    updateIndexes(to);
  }
  function setCurrentRouteIndex(value) {
    currentRouteIndex.value = value;
  }
  function setLastNoModalRouteIndex(value) {
    lastNoModalRouteIndex.value = value;
  }
  function setLastAnonymousRouteIndex(value) {
    lastAnonymousRouteIndex.value = value;
  }
  function setLastAuthorizedRouteIndex(value) {
    lastAuthorizedRouteIndex.value = value;
  }
  function setRealCurrentRouteName(value) {
    realCurrentRouteName.value = value;
  }
  function setPreviousRouteName(value) {
    previousRouteName.value = value;
  }
  function setPreviousRouteUrl(value) {
    previousRouteUrl.value = value;
  }
  function getModalRouteHistoryDelta() {
    if (window.history.length <= 1) {
      return 0;
    }
    const delta = currentRouteIndex.value - lastNoModalRouteIndex.value;
    return delta > 0 ? -delta : 0;
  }
  function getAuthorizedRouteHistoryDelta() {
    if (process.env.VUE_APP_LAYOUT_DESKTOP && lastAuthorizedRouteIndex.value === 0) {
      return 0;
    }
    const delta = currentRouteIndex.value - lastAuthorizedRouteIndex.value;
    return delta > 0 ? -delta : 0;
  }
  function getAnonymousRouteHistoryDelta() {
    if (lastAnonymousRouteIndex.value === 0) {
      return 0;
    }
    const delta = currentRouteIndex.value - lastAnonymousRouteIndex.value;
    return delta > 0 ? -delta : 0;
  }
  function getLoginRedirectRoute() {
    var _a, _b;
    return ((_a = window.history.state) == null ? void 0 : _a.toRoute) || ((_b = window.history.state) == null ? void 0 : _b.back);
  }
  function resetPopState() {
    isPopStateDetected.value = false;
  }
  function setNextRouteName(value) {
    nextRouteName.value = value;
  }
  function registerPopState() {
    if (process.env.VUE_APP_RENDERING_CSR) {
      window.addEventListener("popstate", () => {
        isPopStateDetected.value = true;
      });
    }
  }
  if (process.env.VUE_APP_RENDERING_CSR) {
    registerPopState();
  }
  const analyticsStore = useAnalyticsCommonDataStore();
  function watchAnalyticsData() {
    watch(currentRouteName, (newValue) => {
      analyticsStore.setCurrentRouteName(newValue);
    }, { immediate: true });
    watch(currentRouteFullUrl, (newValue) => {
      analyticsStore.setCurrentFullUrl(newValue);
    }, { immediate: true });
    watch([previousRouteUrl, previousRouteName], () => {
      var _a, _b;
      analyticsStore.setPreviousRouteName((_a = previousRoute.value) == null ? void 0 : _a.name);
      const fullPrevPageUrl = getFullUrlFromPath(((_b = previousRoute.value) == null ? void 0 : _b.fullPath) ?? "");
      analyticsStore.setPreviousRouteUrl(fullPrevPageUrl);
      pushGTM(Events.PAGE_VISIT, {
        event_action: "Page visit"
      }, isRouterInit.value);
    }, { immediate: true });
    isRouterInit.value = true;
  }
  watchAnalyticsData();
  return {
    currentRoute,
    previousRoute,
    seoConfigs,
    isBackButtonInTopBar,
    isEGSHomePageType,
    isSportHomePageType,
    currentRouteName,
    realCurrentRouteName,
    previousRouteName,
    previousRouteUrl,
    isPopStateDetected,
    isCurrentRouteHasTopBar,
    nextRouteName,
    updateAppRouteConfigs,
    handleAppRouteConfigs,
    setBackButtonInTopBar,
    setCurrentRouteIndex,
    setCurrentRoute,
    setLastNoModalRouteIndex,
    getModalRouteHistoryDelta,
    setRealCurrentRouteName,
    getLoginRedirectRoute,
    setPreviousRouteName,
    setPreviousRouteUrl,
    getAuthorizedRouteHistoryDelta,
    getAnonymousRouteHistoryDelta,
    resetPopState,
    setNextRouteName
  };
});
function useWaitForPageHref() {
  const router = useRouter();
  const routerStore = useRouterStore();
  const currentRoute = toRef(() => routerStore.currentRoute);
  const currentRoutePath = computed(() => {
    var _a;
    return (_a = currentRoute.value) == null ? void 0 : _a.path;
  });
  function isSameRoute(currentPath, page) {
    return currentPath === router.resolve(page).path;
  }
  async function waitForPageHref(page) {
    return new Promise((resolve) => {
      if (isSameRoute(currentRoutePath.value, page)) {
        resolve();
        return;
      }
      const unwatch = watch(currentRoutePath, (newValue) => {
        if (isSameRoute(newValue, page)) {
          resolve();
          unwatch();
        }
      });
    });
  }
  return {
    waitForPageHref
  };
}
var ModalDesktopPreset = /* @__PURE__ */ ((ModalDesktopPreset2) => {
  ModalDesktopPreset2["SmallDesktopModal"] = "SmallDesktopModal";
  ModalDesktopPreset2["DepositDetailsDesktopModal"] = "DepositDetailsDesktopModal";
  ModalDesktopPreset2["PrivacyPolicyModal"] = "PrivacyPolicyModal";
  ModalDesktopPreset2["LargeDesktopModal"] = "LargeDesktopModal";
  ModalDesktopPreset2["SearchDesktopModal"] = "SearchDesktopModal";
  ModalDesktopPreset2["ProviderSlotDesktopModal"] = "ProviderSlotDesktopModal";
  ModalDesktopPreset2["ProfileDesktopModal"] = "ProfileDesktopModal";
  ModalDesktopPreset2["EuroBattlePassModal"] = "EuroBattlePassModal";
  ModalDesktopPreset2["RewardCenterAboutModal"] = "RewardCenterAboutModal";
  return ModalDesktopPreset2;
})(ModalDesktopPreset || {});
class AbstractPrefetch {
}
function preparePathname(pathname, currentUrlPrefix) {
  let newPathname = pathname.startsWith("/") ? pathname : `/${pathname}`;
  if (newPathname && newPathname !== "/" && newPathname === `/${currentUrlPrefix}`) {
    newPathname += "/";
  }
  if (newPathname !== `/${currentUrlPrefix}/` && newPathname.endsWith("/")) {
    newPathname = newPathname.slice(0, -1);
  }
  return newPathname || "/";
}
function getNewUri(replacedUrlPrefix, currentUrlPrefix) {
  const pathname = getLocationPath();
  const search = getLocationSearch();
  if (replacedUrlPrefix === currentUrlPrefix) {
    return `${preparePathname(pathname, replacedUrlPrefix)}${search}`;
  }
  const pathnameToSplit = pathname.startsWith("/") ? pathname.substring(1) : pathname;
  const pathnameParts = !pathnameToSplit ? [] : pathnameToSplit.split("/");
  if (replacedUrlPrefix && replacedUrlPrefix === pathnameParts[0]) {
    pathnameParts.shift();
  }
  if (currentUrlPrefix) {
    pathnameParts.unshift(currentUrlPrefix);
  }
  const newPathname = preparePathname(pathnameParts.join("/"), currentUrlPrefix);
  return `${newPathname}${search}`;
}
function prepareRedirectUri(uri) {
  const url = stripPrerenderParametersFromUrl(new URL(`${getLocationOrigin()}${uri}`));
  return `${url.pathname}${url.search}`;
}
export {
  AbstractPrefetch,
  ModalDesktopPreset,
  fetchRoutingConfigs,
  getNewUri,
  prepareRedirectUri,
  useRouterStore,
  useWaitForPageHref
};
