import { AppError } from "@leon-hub/app-errors";
import { updateEnvironment } from "@leon-hub/environment-client";
function getDefinedEnvironment() {
  return __ENVIRONMENT__;
}
function preventChange(key) {
  const error = new AppError({
    message: `EnvironmentPlugin has not been initialized, unable to get/access process.env['${String(key)}']`
  });
  console.error(error);
}
function bootstrapEnvironment() {
  const rawProcessEnvironment = {
    ...process.env,
    ...getDefinedEnvironment()
  };
  const environment = { ...rawProcessEnvironment };
  updateEnvironment({
    environment
  });
  const rawKeys = Object.keys(rawProcessEnvironment);
  function isUpdateAllowed(key) {
    if (rawKeys.includes(String(key))) {
      preventChange(key);
      return false;
    }
    return true;
  }
  if (rawProcessEnvironment.NODE_ENV === "test") {
    process.env = rawProcessEnvironment;
  } else {
    const proxy = new Proxy(process.env, {
      get(target, rawKey) {
        return environment[String(rawKey)];
      },
      deleteProperty(target, key) {
        if (isUpdateAllowed(key)) {
          delete environment[String(key)];
          return true;
        }
        return false;
      },
      set(target, key, value) {
        if (isUpdateAllowed(key)) {
          Object.defineProperty(environment, String(key), { value, configurable: true });
          return true;
        }
        return false;
      }
    });
    process.env = proxy;
  }
}
const EnvironmentPlugin = {
  install(app) {
    app.mixin({
      computed: {
        process: () => ({
          env: process.env
        })
      }
    });
  }
};
export {
  EnvironmentPlugin,
  bootstrapEnvironment,
  getDefinedEnvironment
};
