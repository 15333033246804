import { onMounted, nextTick, getCurrentInstance, toValue, onActivated, onBeforeUnmount, onDeactivated, watch, ref, computed, toRef, isRef, onUnmounted, unref, effectScope } from "vue";
import memoize from "lodash/memoize";
function getLifeCycleTarget(target) {
  return getCurrentInstance();
}
function tryOnMounted(fn, sync = true, target) {
  const instance = getLifeCycleTarget();
  if (instance) {
    onMounted(fn, target);
  } else if (sync) {
    fn();
  } else {
    void nextTick(fn);
  }
}
function unrefElement(elRef) {
  const plain = toValue(elRef);
  return (plain == null ? void 0 : plain.$el) ?? plain;
}
function onComponentActivated(callback) {
  onMounted(callback);
  onActivated(callback);
}
function onComponentDeactivated(callback) {
  onBeforeUnmount(callback);
  onDeactivated(callback);
}
function watchWhenActive(ref2, cb, options) {
  let unwatch;
  onComponentActivated(() => {
    unwatch = watch(ref2, cb, options);
  });
  onComponentDeactivated(() => {
    unwatch == null ? void 0 : unwatch();
    unwatch = void 0;
  });
}
function useControllableResizeObserver(element, callback, options) {
  if (process.env.VUE_APP_RENDERING_SSR || typeof ResizeObserver === "undefined") {
    return {
      isEnabled: ref(false),
      start: () => {
      },
      stop: () => {
      }
    };
  }
  let observer;
  let stopWatch;
  const isEnabled = ref(false);
  const target = computed(() => unrefElement(element));
  function disconnectObserver() {
    if (observer) {
      observer.disconnect();
      observer = void 0;
    }
  }
  function connectObserver() {
    if (!target.value) {
      return;
    }
    observer = new ResizeObserver((entries) => {
      void callback(entries);
    });
    observer.observe(target.value, options);
  }
  function start() {
    stopWatch = stopWatch ?? watch(target, (newValue) => {
      if (newValue) {
        connectObserver();
      } else {
        disconnectObserver();
      }
    }, { immediate: true });
    isEnabled.value = true;
  }
  function stop() {
    disconnectObserver();
    stopWatch == null ? void 0 : stopWatch();
    stopWatch = void 0;
    isEnabled.value = false;
  }
  return {
    isEnabled,
    start,
    stop
  };
}
function useLifecycleResizeObserver(element, callback, options) {
  const { start, stop } = useControllableResizeObserver(element, callback, options);
  onComponentActivated(start);
  onComponentDeactivated(stop);
}
function getWindow() {
  const isClient = typeof window !== "undefined" && typeof document !== "undefined";
  return isClient ? window : void 0;
}
function getBoxSize(entry, box) {
  if (box === "border-box") {
    return entry.borderBoxSize;
  }
  return box === "content-box" ? entry.contentBoxSize : entry.devicePixelContentBoxSize;
}
function useElementSize(target, initialSize = { width: 0, height: 0 }, options = {}) {
  const { box = "content-box" } = options;
  const isSVG = computed(() => {
    var _a, _b;
    return (_b = (_a = unrefElement(target)) == null ? void 0 : _a.namespaceURI) == null ? void 0 : _b.includes("svg");
  });
  const width = ref(initialSize.width);
  const height = ref(initialSize.height);
  const targetRef = toRef(target);
  const { stop: stopResizeObserver, start: startResizeObserver } = useControllableResizeObserver(
    targetRef,
    ([entry]) => {
      const boxSize = getBoxSize(entry, box);
      if (getWindow() && isSVG.value) {
        const $elem = unrefElement(target);
        if ($elem) {
          const rect = $elem.getBoundingClientRect();
          width.value = rect.width;
          height.value = rect.height;
        }
      } else if (boxSize) {
        const formatBoxSize = Array.isArray(boxSize) ? boxSize : [boxSize];
        width.value = formatBoxSize.reduce((acc, { inlineSize }) => acc + inlineSize, 0);
        height.value = formatBoxSize.reduce((acc, { blockSize }) => acc + blockSize, 0);
      } else {
        width.value = entry.contentRect.width;
        height.value = entry.contentRect.height;
      }
    },
    options
  );
  let stopElementChangeWatch;
  function startElementChangeWatch() {
    stopElementChangeWatch = stopElementChangeWatch ?? watch(
      () => unrefElement(target),
      (ele) => {
        width.value = ele ? initialSize.width : 0;
        height.value = ele ? initialSize.height : 0;
      }
    );
  }
  function stop() {
    stopResizeObserver();
    stopElementChangeWatch == null ? void 0 : stopElementChangeWatch();
    stopElementChangeWatch = void 0;
  }
  function start() {
    startResizeObserver();
    startElementChangeWatch();
  }
  tryOnMounted(() => {
    const ele = unrefElement(target);
    if (ele) {
      width.value = "offsetWidth" in ele ? ele.offsetWidth : initialSize.width;
      height.value = "offsetHeight" in ele ? ele.offsetHeight : initialSize.height;
    }
    start();
  });
  return {
    width,
    height,
    start,
    stop
  };
}
function isEmptyElementRef(element) {
  return isRef(element) && !element.value;
}
function getHtmlElement(element) {
  if (!element) {
    return void 0;
  }
  if (element instanceof Element) {
    return element;
  }
  return element.$el;
}
function useIntersectionObserver(element, callback, options, onceOnIntersecting) {
  if (typeof IntersectionObserver === "undefined") {
    return;
  }
  let observer = null;
  function disconnectObserver() {
    if (observer) {
      observer.disconnect();
      observer = null;
    }
  }
  function connectObserver() {
    const unrefElement2 = getHtmlElement(unref(element));
    if (!unrefElement2) return;
    observer = new IntersectionObserver((entries) => {
      var _a;
      const isIntersecting = (_a = entries[0]) == null ? void 0 : _a.isIntersecting;
      if (onceOnIntersecting && isIntersecting) {
        void callback(isIntersecting);
        disconnectObserver();
      } else {
        void callback(isIntersecting);
      }
    }, options);
    observer.observe(unrefElement2);
  }
  if (isEmptyElementRef(element)) {
    const unwatchElement = watch(element, (newValue) => {
      if (newValue) {
        connectObserver();
        unwatchElement();
      }
    });
  } else {
    onMounted(connectObserver);
  }
  onUnmounted(disconnectObserver);
}
function defineStatefulComposable(callback) {
  const memoizedScope = memoize(() => {
    const scope = effectScope(true);
    const result = scope.run(() => callback());
    if (!result) {
      throw new Error("Failed to initialize effect scope.");
    }
    return { result, scope };
  });
  return () => {
    const { result } = memoizedScope();
    return result;
  };
}
export {
  defineStatefulComposable,
  isEmptyElementRef,
  onComponentActivated,
  onComponentDeactivated,
  useControllableResizeObserver,
  useElementSize,
  useIntersectionObserver,
  useLifecycleResizeObserver,
  watchWhenActive
};
