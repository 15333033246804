import { ref, computed, watch, toRef } from "vue";
import { defineStore } from "pinia";
import { getSiteConfigSettings, RecaptchaTheme, CaptchaType, CaptchaStatus, Web2FontFamily, HomePageType, helpChatTypeNone, ZetaDcpConfigType, AppflowUpdateMethod } from "@leon-hub/api-sdk";
import { toBoolean } from "@leon-hub/utils";
import { useIsLoggedIn } from "@core/auth";
import { useSyncState, useModuleTimersStore } from "@core/sync-state";
import { useGraphqlClient } from "@core/app-rest-client";
function fetchSiteConfigSettings({ silent } = {}) {
  return getSiteConfigSettings(useGraphqlClient(), (node) => node.queries.system.siteConfig, {}, { silent });
}
function getDefaultCaptchaConfig() {
  return {
    loginCaptchaStatus: CaptchaStatus.OFF,
    passwordRecoveryCaptchaStatus: CaptchaStatus.OFF,
    registrationCaptchaStatus: CaptchaStatus.OFF,
    loginCaptchaType: CaptchaType.RECAPTCHA,
    passwordRecoveryCaptchaType: CaptchaType.RECAPTCHA,
    registrationCaptchaType: CaptchaType.RECAPTCHA,
    captchaSecuritySignEnabled: false,
    useIframeForCaptcha: false,
    captchaApiUrl: "",
    captchaIframeUrl: "",
    recaptcha: {
      theme: RecaptchaTheme.LIGHT,
      siteKeys: []
    },
    recaptchaV3: {
      loginEnabled: false,
      passwordRecoveryEnabled: false,
      registrationEnabled: false,
      siteKey: void 0,
      timeout: 1e4
    }
  };
}
const useSiteConfigStore = /* @__PURE__ */ defineStore("site-config", () => {
  const DEFAULT_SEARCH_SPORTLINE_MINCHARS = 3;
  const DEFAULT_SUGGESTED_EMAIL_REGISTRATION_TIMEOUT = 60;
  const DEFAULT_SMS_RESEND_CODE_TIMER = 90;
  const { isLoggedIn } = useIsLoggedIn();
  useSyncState((silent) => updateSiteConfigSettings({ silent }), "site-config");
  const siteConfig = ref(Object.freeze({
    captcha: getDefaultCaptchaConfig()
  }));
  const errorHandlerSettings = computed(() => {
    var _a;
    return (_a = siteConfig.value.errorHandler) == null ? void 0 : _a.settings;
  });
  const sentryOptions = computed(() => {
    const options = {};
    const errorHandlerSettingsValue = errorHandlerSettings.value;
    if (errorHandlerSettingsValue) {
      for (const parameter of errorHandlerSettingsValue.params) {
        Object.assign(options, {
          [parameter.key]: toBoolean(parameter.value)
        });
      }
    }
    return options;
  });
  const zetaDSPConfig = computed(() => {
    var _a;
    return ((_a = siteConfig.value.zetaDcp) == null ? void 0 : _a.configs) ?? [];
  });
  const widgetsBlock = computed(() => siteConfig.value.widgets);
  const landing = computed(() => siteConfig.value.landing);
  const jsBundlesCDNUrl = computed(() => {
    var _a;
    return (_a = siteConfig.value.settings) == null ? void 0 : _a.jsBundlesCDNUrl;
  });
  const accessOptionsBlock = computed(() => siteConfig.value.accessOptions);
  const { setDefaultTimeout, setModuleTimeouts } = useModuleTimersStore();
  async function updateSiteConfigSettings({ silent } = {}) {
    handleSiteConfigSettings(await fetchSiteConfigSettings({ silent }));
  }
  function handleSiteConfigSettings(result) {
    const mergeResult = result.mobileAppCordova ? {
      ...result,
      mobileAppCordova: {
        ...result.mobileAppCordova,
        mobileAppUpdateConfigCustomerSpecific: isLoggedIn.value ? result.mobileAppCordova.mobileAppUpdateConfigCustomerSpecific : {
          ...result.mobileAppCordova.mobileAppUpdateConfigCustomerSpecific,
          isUpdateAvailable: false,
          isApkUpdateMandatory: false
        }
      }
    } : result;
    siteConfig.value = Object.freeze({
      ...siteConfig.value,
      ...mergeResult
    });
    if (result.settings) {
      setDefaultTimeout(result.settings.modulesDefaultUpdateInterval);
      if (result.settings.modulesCustomUpdateInterval) {
        setModuleTimeouts(result.settings.modulesCustomUpdateInterval.reduce(
          (accumulator, item) => {
            accumulator[item.name] = item.interval;
            return accumulator;
          },
          {}
        ));
      }
    }
  }
  function init() {
    if (!process.env.VUE_APP_BUNDLER_NUXT && !process.env.VUE_APP_PRERENDER && !process.env.VUE_APP_PLATFORM_CORDOVA && !process.env.VUE_APP_STATIC_CDN_URL) {
      watch(jsBundlesCDNUrl, (newJsBundlesCDNUrl) => {
        if (newJsBundlesCDNUrl) {
          __webpack_public_path__ = newJsBundlesCDNUrl;
        }
      }, {
        immediate: true
      });
    }
  }
  init();
  return {
    // Getters
    isThemeSwitcherEnabled: computed(() => {
      var _a;
      return !!((_a = siteConfig.value.settings) == null ? void 0 : _a.themeSwitcherEnabled);
    }),
    themes: computed(() => {
      var _a;
      return ((_a = siteConfig.value.settings) == null ? void 0 : _a.themes) ?? [];
    }),
    isSubscriptionsInvertedEnabled: computed(() => {
      var _a;
      return !!((_a = siteConfig.value.settings) == null ? void 0 : _a.isSubscriptionsInvertedEnabled);
    }),
    bookmakerRatingsUrl: computed(() => {
      var _a;
      return (_a = siteConfig.value.socialNetworks) == null ? void 0 : _a.bookmakerRatingsUrl;
    }),
    socialMediaRssUrl: computed(() => {
      var _a;
      return (_a = siteConfig.value.socialNetworks) == null ? void 0 : _a.socialMediaRssUrl;
    }),
    socialMediaVkUrl: computed(() => {
      var _a;
      return (_a = siteConfig.value.socialNetworks) == null ? void 0 : _a.socialMediaVkUrl;
    }),
    socialMediaInstagramUrl: computed(() => {
      var _a;
      return (_a = siteConfig.value.socialNetworks) == null ? void 0 : _a.socialMediaInstagramUrl;
    }),
    socialMediaTelegramUrl: computed(() => {
      var _a;
      return (_a = siteConfig.value.socialNetworks) == null ? void 0 : _a.socialMediaTelegramUrl;
    }),
    socialMediaTwitterUrl: computed(() => {
      var _a;
      return (_a = siteConfig.value.socialNetworks) == null ? void 0 : _a.socialMediaTwitterUrl;
    }),
    untrustedDevicePasswordResetRedirectTimer: computed(() => {
      var _a;
      return (_a = siteConfig.value.settings) == null ? void 0 : _a.untrustedDevicePasswordResetRedirectTimer;
    }),
    appVloaderDelayMs: computed(() => {
      var _a;
      return (_a = siteConfig.value.settings) == null ? void 0 : _a.appVloaderDelayMs;
    }),
    sportLineBlock: computed(() => siteConfig.value.sportLine),
    sportsbookUIVersion: computed(() => siteConfig.value.sportsbookUIVersion),
    loginTypes: computed(() => {
      var _a;
      return (_a = siteConfig.value.login) == null ? void 0 : _a.configs;
    }),
    sportEventsBlock: computed(() => siteConfig.value.sportEvents),
    downtimeBlock: computed(() => siteConfig.value.downtime),
    licenseBlock: computed(() => siteConfig.value.license),
    errorHandlerSettings,
    sentryOptions,
    ignoredErrorsCommon: computed(() => {
      var _a;
      return ((_a = siteConfig.value.errorHandler) == null ? void 0 : _a.ignoredErrorsCommon) ?? [];
    }),
    metricsToolsBlock: computed(() => siteConfig.value.metricsTools),
    slipBlock: computed(() => siteConfig.value.slip),
    supportBlock: computed(() => siteConfig.value.support),
    isOldFaqEnabled: computed(() => {
      var _a;
      return !!((_a = siteConfig.value.support) == null ? void 0 : _a.faqEnabled);
    }),
    pushNotificationsBlock: computed(() => siteConfig.value.pushNotifications),
    pushNotificationsWebBlock: computed(() => siteConfig.value.pushNotificationsWeb),
    promotionsBlock: computed(() => siteConfig.value.promotions),
    bonusLinkLoyalty: computed(() => {
      var _a;
      return (_a = siteConfig.value.bonusWallet) == null ? void 0 : _a.bonusLinkLoyalty;
    }),
    bonusLinkEgsLoyalty: computed(() => {
      var _a;
      return (_a = siteConfig.value.bonusWallet) == null ? void 0 : _a.bonusLinkEgsLoyalty;
    }),
    isStartScreenBonusEnabled: computed(() => {
      var _a;
      return !!((_a = siteConfig.value.bonusWallet) == null ? void 0 : _a.startBonusScreenEnabled);
    }),
    identificationBlock: computed(() => siteConfig.value.identification),
    popularSearchQueries: computed(() => {
      var _a;
      return ((_a = siteConfig.value.popularSearchQueries) == null ? void 0 : _a.popularSearchQueriesData) ?? [];
    }),
    feedbackFormMaxChars: computed(() => {
      var _a;
      return ((_a = siteConfig.value.settings) == null ? void 0 : _a.feedbackFormMaxChars) || 500;
    }),
    isBrowserFingerprintEnabled: computed(() => {
      var _a;
      return Boolean((_a = siteConfig.value.security) == null ? void 0 : _a.browserFingerprintingEnabled);
    }),
    isFingerprintJsEnabled: computed(() => {
      var _a;
      return Boolean((_a = siteConfig.value.fJs) == null ? void 0 : _a.isEnabled);
    }),
    faceTouchIDReminderFrequency: computed(() => {
      var _a;
      return ((_a = siteConfig.value.security) == null ? void 0 : _a.faceTouchIDReminderFrequency) || 0;
    }),
    faceTouchIDReminderCount: computed(() => {
      var _a;
      return ((_a = siteConfig.value.security) == null ? void 0 : _a.faceTouchIDReminderCount) || 0;
    }),
    g2svInactiveInterval: computed(() => {
      var _a;
      return (_a = siteConfig.value.security) == null ? void 0 : _a.g2svInactiveInterval;
    }),
    phoneWrongAttemptsLimit: computed(() => {
      var _a;
      return ((_a = siteConfig.value.security) == null ? void 0 : _a.phoneWrongAttemptsLimit) || 1;
    }),
    pinCodeRequestedPlaces: computed(() => {
      var _a;
      return ((_a = siteConfig.value.security) == null ? void 0 : _a.pinCodeRequestedPlaces) ?? null;
    }),
    pinCodeModalShowLimit: computed(() => {
      var _a;
      return ((_a = siteConfig.value.security) == null ? void 0 : _a.pinCodeModalShowLimit) || 0;
    }),
    pinCodeWrongAttemptsLimit: computed(() => {
      var _a;
      return ((_a = siteConfig.value.security) == null ? void 0 : _a.pinCodeWrongAttemptsLimit) || 0;
    }),
    isShieldEnabled: computed(() => {
      var _a;
      return !!((_a = siteConfig.value.security) == null ? void 0 : _a.shieldEnabled);
    }),
    helmetAPIUrl: computed(() => {
      var _a;
      return ((_a = siteConfig.value.security) == null ? void 0 : _a.helmetAPIUrl) || void 0;
    }),
    isLexGuardEnabled: computed(() => {
      var _a;
      return !!((_a = siteConfig.value.security) == null ? void 0 : _a.lexGuardEnabled);
    }),
    lexGuardAPIUrl: computed(() => {
      var _a;
      return ((_a = siteConfig.value.security) == null ? void 0 : _a.lexGuardAPIUrl) || void 0;
    }),
    lexGuardOrgId: computed(() => {
      var _a;
      return ((_a = siteConfig.value.security) == null ? void 0 : _a.lexGuardOrgId) || void 0;
    }),
    lexGuardProfilingSubdomain: computed(() => {
      var _a;
      return ((_a = siteConfig.value.security) == null ? void 0 : _a.lexGuardProfilingSubdomain) || void 0;
    }),
    searchMinCharsSize: computed(() => {
      var _a;
      return ((_a = siteConfig.value.search) == null ? void 0 : _a.searchSportlineMinchars) || DEFAULT_SEARCH_SPORTLINE_MINCHARS;
    }),
    mobileBlock: computed(() => siteConfig.value.mobileBlocks),
    mobileAppCordovaBlock: computed(() => siteConfig.value.mobileAppCordova),
    isAndroidAppDownloadEnabled: computed(() => {
      var _a;
      return ((_a = siteConfig.value.mobileAppCordova) == null ? void 0 : _a.mobileAppAndroidDownloadEnabled) ?? false;
    }),
    isTPWidgetEnabled: computed(() => {
      var _a;
      return ((_a = siteConfig.value.tpWidget) == null ? void 0 : _a.isTPWidgetEnabled) ?? false;
    }),
    tpWidgetLandingUrl: computed(() => {
      var _a;
      return (_a = siteConfig.value.tpWidget) == null ? void 0 : _a.tpWidgetLandingUrl;
    }),
    apkUpdateCheckInterval: computed(() => {
      var _a;
      return (_a = siteConfig.value.mobileAppCordova) == null ? void 0 : _a.mobile5UpdateCheckPeriod;
    }),
    apkCommonUpdateData: computed(() => {
      var _a;
      return (_a = siteConfig.value.mobileAppCordova) == null ? void 0 : _a.mobileAppUpdateConfigGeneric;
    }),
    apkCustomerUpdateData: computed(() => {
      var _a;
      return (_a = siteConfig.value.mobileAppCordova) == null ? void 0 : _a.mobileAppUpdateConfigCustomerSpecific;
    }),
    appflowConfig: computed(() => {
      var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n, _o, _p, _q, _r;
      return {
        isEnabled: ((_b = (_a = siteConfig.value.appflow) == null ? void 0 : _a.config) == null ? void 0 : _b.isEnabled) ?? false,
        doReset: ((_d = (_c = siteConfig.value.appflow) == null ? void 0 : _c.config) == null ? void 0 : _d.doReset) ?? false,
        appId: ((_f = (_e = siteConfig.value.appflow) == null ? void 0 : _e.config) == null ? void 0 : _f.appId) ?? "",
        channel: ((_h = (_g = siteConfig.value.appflow) == null ? void 0 : _g.config) == null ? void 0 : _h.channel) ?? "",
        maxVersions: ((_j = (_i = siteConfig.value.appflow) == null ? void 0 : _i.config) == null ? void 0 : _j.maxVersions) ?? 2,
        minBackgroundDuration: ((_l = (_k = siteConfig.value.appflow) == null ? void 0 : _k.config) == null ? void 0 : _l.minBackgroundDuration) ?? 300,
        updateMethod: ((_n = (_m = siteConfig.value.appflow) == null ? void 0 : _m.config) == null ? void 0 : _n.updateMethod) ?? AppflowUpdateMethod.NONE,
        host: ((_p = (_o = siteConfig.value.appflow) == null ? void 0 : _o.config) == null ? void 0 : _p.host) ?? "",
        debug: Boolean((_r = (_q = siteConfig.value.appflow) == null ? void 0 : _q.config) == null ? void 0 : _r.debug)
      };
    }),
    domainsCheckConfig: computed(() => {
      var _a, _b, _c;
      return {
        checkInterval: (_a = siteConfig.value.domainCheck) == null ? void 0 : _a.checkInterval,
        msgMaxSizeBytes: (_b = siteConfig.value.domainCheck) == null ? void 0 : _b.msgMaxSizeBytes,
        domains: (_c = siteConfig.value.domainCheck) == null ? void 0 : _c.domains
      };
    }),
    appDownloadAdBlock: computed(() => siteConfig.value.appDownloadAd),
    appsFlyerSupportedSdkEvents: computed(() => {
      var _a;
      return ((_a = siteConfig.value.registration) == null ? void 0 : _a.appsFlyerSupportedSdkEvents) ?? [];
    }),
    smsResendCodeTimer: computed(() => {
      var _a;
      return ((_a = siteConfig.value.registration) == null ? void 0 : _a.smsResendCodeTimer) || DEFAULT_SMS_RESEND_CODE_TIMER;
    }),
    phoneCodeLengthSettings: computed(() => {
      var _a;
      const phoneCodeLength = ((_a = siteConfig.value.registration) == null ? void 0 : _a.phoneCodeLength) ?? [];
      const phoneCodeLengthEntries = phoneCodeLength.map((phoneCode) => [phoneCode.phoneCodeMethod, phoneCode.value]);
      return Object.fromEntries(phoneCodeLengthEntries);
    }),
    bonusCodeInputFormStatus: computed(() => {
      var _a;
      return (_a = siteConfig.value.bonusWallet) == null ? void 0 : _a.bonusCodeInputFormStatus;
    }),
    zetaDSPConfig,
    zetaDSPisEnabled: computed(() => {
      var _a;
      return ((_a = siteConfig.value.zetaDcp) == null ? void 0 : _a.isEnabled) ?? false;
    }),
    zetaDSPMainType: computed(() => zetaDSPConfig.value.find((item) => item.type === ZetaDcpConfigType.MAIN)),
    isCasAuthEnabled: computed(() => {
      var _a;
      return Boolean((_a = siteConfig.value.sso) == null ? void 0 : _a.enabled);
    }),
    googlePlayIconUrl: computed(() => {
      var _a;
      return ((_a = siteConfig.value.settings) == null ? void 0 : _a.googlePlayIconUrl) || "";
    }),
    appleStoreIconUrl: computed(() => {
      var _a;
      return ((_a = siteConfig.value.settings) == null ? void 0 : _a.appleStoreIconUrl) || "";
    }),
    linkCdnUrl: computed(() => {
      var _a;
      return ((_a = siteConfig.value.settings) == null ? void 0 : _a.linkCDNUrl) || "";
    }),
    sportradarCounterId: computed(() => {
      var _a;
      return (_a = siteConfig.value.sportradar) == null ? void 0 : _a.counterId;
    }),
    fastTrackBlock: computed(() => siteConfig.value.fastTrack),
    fastGamesBlock: computed(() => siteConfig.value.fastGames),
    firebaseBlock: computed(() => siteConfig.value.firebase),
    widgetsBlock,
    widgetIframeUrl: computed(() => {
      var _a;
      return (_a = widgetsBlock.value) == null ? void 0 : _a.iframeUrl;
    }),
    sportradar: computed(() => siteConfig.value.sportradar),
    captcha: computed(() => siteConfig.value.captcha),
    externalGames: computed(() => siteConfig.value.externalGames),
    virtualSport: computed(() => siteConfig.value.virtualSport),
    egsSkeletons: computed(() => siteConfig.value.egsSkeletons),
    vpnLoader: computed(() => siteConfig.value.vpnLoader),
    isSportradarLiveMatchTrackerEnabled: computed(() => {
      var _a;
      return !!((_a = widgetsBlock.value) == null ? void 0 : _a.sportradarLiveMatchTrackerEnabled);
    }),
    isSportradarStatisticsEnabled: computed(() => {
      var _a;
      return !!((_a = widgetsBlock.value) == null ? void 0 : _a.sportradarStatisticsEnabled);
    }),
    isSportradarLiveScoreEnabled: computed(() => {
      var _a;
      return !!((_a = widgetsBlock.value) == null ? void 0 : _a.sportradarLiveScoreEnabled);
    }),
    isServiceSuspended: computed(() => {
      var _a;
      return (_a = siteConfig.value.settings) == null ? void 0 : _a.isServiceSuspended;
    }),
    isPhoneTabEnabledOnPasswordRestorePage: computed(() => {
      var _a;
      return !!((_a = siteConfig.value.registration) == null ? void 0 : _a.isPhoneTabEnabledOnPasswordRestorePage);
    }),
    isEmailTabEnabledOnPasswordRestorePage: computed(() => {
      var _a;
      return !!((_a = siteConfig.value.registration) == null ? void 0 : _a.isEmailTabEnabledOnPasswordRestorePage);
    }),
    isSumSubWebSdkInProdMode: computed(() => {
      var _a;
      return !!((_a = siteConfig.value.sumSub) == null ? void 0 : _a.isWebSdkInProdMode);
    }),
    sumSubUrl: computed(() => {
      var _a;
      return ((_a = siteConfig.value.sumSub) == null ? void 0 : _a.sdkUrl) || "";
    }),
    isSubscriptionBlockEnabled: computed(() => {
      var _a;
      return !!((_a = siteConfig.value.profile) == null ? void 0 : _a.subscriptionBlockEnabled);
    }),
    isProfileReferralProgramEnabled: computed(() => {
      var _a;
      return !!((_a = siteConfig.value.profile) == null ? void 0 : _a.profileReferralProgramEnabled);
    }),
    isReferralProgramMainBlockEnabled: computed(() => {
      var _a;
      return !!((_a = siteConfig.value.profile) == null ? void 0 : _a.referralProgramMainBlockEnabled);
    }),
    isProfileBetsCounterEnabled: computed(() => {
      var _a;
      return !!((_a = siteConfig.value.profile) == null ? void 0 : _a.profileBetsCounterEnabled);
    }),
    isProfilePersonalDataDisallowUserInputEnabled: computed(() => {
      var _a;
      return !!((_a = siteConfig.value.profile) == null ? void 0 : _a.profilePersonalDataDisallowUserInputEnabled);
    }),
    isFaqBlockEnabled: computed(() => {
      var _a;
      return !!((_a = siteConfig.value.faq) == null ? void 0 : _a.faqEnabled);
    }),
    affiliateMigrationLink: computed(() => {
      var _a;
      return ((_a = siteConfig.value.settings) == null ? void 0 : _a.affiliateMigrationLink) || "";
    }),
    isSuggestedEmailRegistrationEnabled: computed(() => {
      var _a;
      return !!((_a = siteConfig.value.registration) == null ? void 0 : _a.isSuggestedEmailRegistrationEnabled);
    }),
    suggestedEmailRegistrationTimeout: computed(() => {
      var _a;
      return ((_a = siteConfig.value.registration) == null ? void 0 : _a.suggestedEmailRegistrationTimeout) || DEFAULT_SUGGESTED_EMAIL_REGISTRATION_TIMEOUT;
    }),
    isLivechatAnonymEnabled: computed(() => {
      var _a;
      return !!((_a = siteConfig.value.support) == null ? void 0 : _a.isLivechatAnonymEnabled);
    }),
    helpChatType: computed(() => {
      var _a;
      return ((_a = siteConfig.value.support) == null ? void 0 : _a.helpChatType) ?? helpChatTypeNone;
    }),
    isResponsibleGamblingFeedbackEnabled: computed(() => {
      var _a;
      return !!((_a = siteConfig.value.support) == null ? void 0 : _a.isResponsibleGamblingFeedbackEnabled);
    }),
    isRulesTitleEnabled: computed(() => {
      var _a;
      return !!((_a = siteConfig.value.rules) == null ? void 0 : _a.isTitleEnabled);
    }),
    isRulesNavigationOpen: computed(() => {
      var _a;
      return !!((_a = siteConfig.value.rules) == null ? void 0 : _a.isNavigationOpen);
    }),
    isRulesSearchEnabled: computed(() => {
      var _a;
      return !!((_a = siteConfig.value.rules) == null ? void 0 : _a.isSearchEnabled);
    }),
    isRulesMobileTabsEnabled: computed(() => {
      var _a;
      return !!((_a = siteConfig.value.rules) == null ? void 0 : _a.isMobileTabsEnabled);
    }),
    loyaltyProgramEgsStatus: computed(() => {
      var _a;
      return (_a = siteConfig.value.bonusWallet) == null ? void 0 : _a.loyaltyProgramEgsStatus;
    }),
    postponedModalsShowTimeout: computed(() => {
      var _a;
      return ((_a = siteConfig.value.fastTrack) == null ? void 0 : _a.modalsShowTimeout) || 3e3;
    }),
    bonusesEnable: computed(() => {
      var _a;
      return (_a = siteConfig.value.bonuses) == null ? void 0 : _a.bonusesEnable;
    }),
    bonusesPolling: computed(() => {
      var _a;
      return (_a = siteConfig.value.bonuses) == null ? void 0 : _a.bonusesPolling;
    }),
    isLeonShopEnabled: computed(() => {
      var _a;
      return !!((_a = siteConfig.value.bonuses) == null ? void 0 : _a.isLeonShopEnabled);
    }),
    isEgsStoryBannerEnabled: computed(() => {
      var _a;
      return !!((_a = siteConfig.value.externalGames) == null ? void 0 : _a.isStoryBannerEnabled);
    }),
    delayBeforeNewBonusesListRequest: computed(() => {
      var _a;
      return (_a = siteConfig.value.bonusWallet) == null ? void 0 : _a.delayBeforeNewBonusesListRequest;
    }),
    idleRegistrationProps: computed(() => {
      var _a, _b, _c, _d;
      return {
        idleRedirectCount: ((_a = siteConfig.value.registration) == null ? void 0 : _a.idleRedirectCount) || 0,
        idleTimeToRedirect: ((_b = siteConfig.value.registration) == null ? void 0 : _b.idleTimeToRedirect) || 0,
        marketingLinkIdleRedirect: ((_c = siteConfig.value.registration) == null ? void 0 : _c.marketingLinkIdleRedirect) || 0,
        marketingLinkParam: (_d = siteConfig.value.registration) == null ? void 0 : _d.marketingLinkParam
      };
    }),
    isPushNotificationsEnabled: computed(() => {
      var _a;
      return !!((_a = siteConfig.value.pushNotifications) == null ? void 0 : _a.enabled);
    }),
    isForcedLayoutEnabled: computed(() => {
      var _a;
      return !!((_a = siteConfig.value.settings) == null ? void 0 : _a.forcedLayoutEnabled);
    }),
    showModalOnLeaveEnabled: computed(() => {
      var _a;
      return !!((_a = siteConfig.value.registration) == null ? void 0 : _a.showModalOnLeaveEnabled);
    }),
    fingerprintJsBlock: computed(() => siteConfig.value.fJs),
    isRegistrationDisabled: computed(() => {
      var _a;
      return !!((_a = siteConfig.value.registration) == null ? void 0 : _a.isRegistrationDisabled);
    }),
    formsEmailHintsEnabled: computed(() => {
      var _a;
      return !!((_a = siteConfig.value.registration) == null ? void 0 : _a.formsEmailHintsEnabled);
    }),
    formsEmailHints: computed(() => {
      var _a;
      return ((_a = siteConfig.value.registration) == null ? void 0 : _a.formsEmailHints) ?? [];
    }),
    registrationBanners: computed(() => {
      var _a;
      return ((_a = siteConfig.value.registration) == null ? void 0 : _a.footerBannerLogotypesByLocale) ?? [];
    }),
    isOldFooterLogotypesEnabled: computed(() => {
      var _a;
      return !!((_a = siteConfig.value.footer) == null ? void 0 : _a.isFooterOldLogotypesEnabled);
    }),
    isFooterLogotypesEnabled: computed(() => {
      var _a;
      return !!((_a = siteConfig.value.footer) == null ? void 0 : _a.isFooterLogotypesEnabled);
    }),
    isFooterSocialNetworksEnabled: computed(() => {
      var _a;
      return !!((_a = siteConfig.value.footer) == null ? void 0 : _a.isFooterSocialNetworksEnabled);
    }),
    footerLogotypesBlock: computed(() => siteConfig.value.footer),
    isPromotionsHighlightLinkEnabled: computed(() => {
      var _a;
      return !!((_a = siteConfig.value.promotions) == null ? void 0 : _a.isPromotionsHighlightLinkEnabled);
    }),
    isEgsLoyaltyEnabled: computed(() => {
      var _a;
      return !!((_a = siteConfig.value.loyalty) == null ? void 0 : _a.isEgsLoyaltyEnabled);
    }),
    isLoyaltyEnabled: computed(() => {
      var _a;
      return !!((_a = siteConfig.value.loyalty) == null ? void 0 : _a.isLoyaltyEnabled);
    }),
    isLoyaltyEnabledMain: computed(() => {
      var _a;
      return !!((_a = siteConfig.value.loyalty) == null ? void 0 : _a.isLoyaltyEnabledMain);
    }),
    isEgsLoyaltyProgramLvlUpModelEnabled: computed(() => {
      var _a;
      return !!((_a = siteConfig.value.loyalty) == null ? void 0 : _a.isEgsLoyaltyProgramLvlUpModelEnabled);
    }),
    isHelpEmailEnabled: computed(() => {
      var _a;
      return !!((_a = siteConfig.value.support) == null ? void 0 : _a.isHelpEmailEnabled);
    }),
    landing,
    isLandingWelcomeEnabled: computed(() => {
      var _a;
      return !!((_a = landing.value) == null ? void 0 : _a.isLandingWelcomeEnabled);
    }),
    isLandingAndroidEnabled: computed(() => {
      var _a;
      return !!((_a = landing.value) == null ? void 0 : _a.isLandingAndroidEnabled);
    }),
    landingAndroidImageHeader: computed(() => {
      var _a;
      return (_a = landing.value) == null ? void 0 : _a.landingAndroidImageHeader;
    }),
    landingAndroidImageGamblingFirst: computed(() => {
      var _a;
      return (_a = landing.value) == null ? void 0 : _a.landingAndroidImageGamblingFirst;
    }),
    landingAndroidImageGamblingSecond: computed(() => {
      var _a;
      return (_a = landing.value) == null ? void 0 : _a.landingAndroidImageGamblingSecond;
    }),
    landingAndroidImageGamblingThird: computed(() => {
      var _a;
      return (_a = landing.value) == null ? void 0 : _a.landingAndroidImageGamblingThird;
    }),
    landingAndroidImageFree: computed(() => {
      var _a;
      return (_a = landing.value) == null ? void 0 : _a.landingAndroidImageFree;
    }),
    landingAndroidImageBonusFirst: computed(() => {
      var _a;
      return (_a = landing.value) == null ? void 0 : _a.landingAndroidImageBonusFirst;
    }),
    landingAndroidImageBonusSecond: computed(() => {
      var _a;
      return (_a = landing.value) == null ? void 0 : _a.landingAndroidImageBonusSecond;
    }),
    landingAndroidImageBonusThird: computed(() => {
      var _a;
      return (_a = landing.value) == null ? void 0 : _a.landingAndroidImageBonusThird;
    }),
    landingAndroidImageBackground: computed(() => {
      var _a;
      return (_a = landing.value) == null ? void 0 : _a.landingAndroidImageBackground;
    }),
    landingAndroidImageFooter: computed(() => {
      var _a;
      return (_a = landing.value) == null ? void 0 : _a.landingAndroidImageFooter;
    }),
    landingCouponsBackground: computed(() => {
      var _a;
      return (_a = landing.value) == null ? void 0 : _a.landingCouponsBackground;
    }),
    landingCouponsMainImage: computed(() => {
      var _a;
      return (_a = landing.value) == null ? void 0 : _a.landingCouponsMainImage;
    }),
    landingCouponsSecondaryImage: computed(() => {
      var _a;
      return (_a = landing.value) == null ? void 0 : _a.landingCouponsSecondaryImage;
    }),
    landingFestEmbed: computed(() => {
      var _a;
      return (_a = landing.value) == null ? void 0 : _a.landingFestEmbed;
    }),
    festPromoDetailsLink: computed(() => {
      var _a;
      return (_a = landing.value) == null ? void 0 : _a.festPromoDetailsLink;
    }),
    androidSocialNetworks: computed(() => {
      var _a;
      return (_a = landing.value) == null ? void 0 : _a.androidSocialNetworks;
    }),
    isPromotionsRequestNicknameEnabled: computed(() => {
      var _a;
      return !!((_a = siteConfig.value.promotions) == null ? void 0 : _a.isPromotionsRequestNicknameEnabled);
    }),
    isSumOfBonusAndBalanceEnabled: computed(() => {
      var _a;
      return !!((_a = siteConfig.value.header) == null ? void 0 : _a.isHeaderBalanceEnabled);
    }),
    isVideoVerificationFeatureEnabled: computed(() => {
      var _a;
      return !!((_a = siteConfig.value.verification) == null ? void 0 : _a.isVideoVerificationEnabled);
    }),
    promoActionLinks: computed(() => {
      var _a;
      return ((_a = landing.value) == null ? void 0 : _a.promoActionLinks) ?? [];
    }),
    welcomeEgsCategory: computed(() => {
      var _a;
      return (_a = landing.value) == null ? void 0 : _a.welcomeEgsCategory;
    }),
    isLandingFestivalEnabled: computed(() => {
      var _a;
      return !!((_a = landing.value) == null ? void 0 : _a.isLandingFestivalEnabled);
    }),
    isLandingVipEnabled: computed(() => {
      var _a;
      return !!((_a = landing.value) == null ? void 0 : _a.isLandingVipEnabled);
    }),
    landingVipFaqItems: computed(() => {
      var _a;
      return ((_a = landing.value) == null ? void 0 : _a.landingVipFaqItems) ?? [];
    }),
    isAdventCalendarEnabled: computed(() => {
      var _a;
      return !!((_a = landing.value) == null ? void 0 : _a.isAdventCalendarEnabled);
    }),
    landingAdventBackgroundImage: computed(() => {
      var _a;
      return (_a = landing.value) == null ? void 0 : _a.landingAdventBackgroundImage;
    }),
    landingAndroidAnimationRightTop: computed(() => {
      var _a;
      return (_a = landing.value) == null ? void 0 : _a.landingAndroidAnimationRightTop;
    }),
    landingAndroidAnimationRightMiddle: computed(() => {
      var _a;
      return (_a = landing.value) == null ? void 0 : _a.landingAndroidAnimationRightMiddle;
    }),
    landingAndroidAnimationRightBottom: computed(() => {
      var _a;
      return (_a = landing.value) == null ? void 0 : _a.landingAndroidAnimationRightBottom;
    }),
    landingAndroidAnimationLeftTop: computed(() => {
      var _a;
      return (_a = landing.value) == null ? void 0 : _a.landingAndroidAnimationLeftTop;
    }),
    landingAndroidAnimationLeftBottom: computed(() => {
      var _a;
      return (_a = landing.value) == null ? void 0 : _a.landingAndroidAnimationLeftBottom;
    }),
    isPartnersEnabled: computed(() => {
      var _a;
      return !!((_a = siteConfig.value.partner) == null ? void 0 : _a.isPartnersEnabled);
    }),
    isPartnersV2Enabled: computed(() => {
      var _a;
      return !!((_a = siteConfig.value.partner) == null ? void 0 : _a.isPartnersV2Enabled);
    }),
    isPromotionsLikeEnabled: computed(() => {
      var _a;
      return !!((_a = siteConfig.value.promotions) == null ? void 0 : _a.isPromotionsLikeEnabled);
    }),
    isBonusListBeforeDepositEnabled: computed(() => {
      var _a;
      return ((_a = siteConfig.value.deposits) == null ? void 0 : _a.bonusListBeforeDepositEnabled) ?? false;
    }),
    promotionsLikeFeedbackCountdown: computed(() => {
      var _a;
      return ((_a = siteConfig.value.promotions) == null ? void 0 : _a.promotionsLikeFeedbackCountdown) || 0;
    }),
    isWeb2BonusCashbackWidgetEnabled: computed(() => {
      var _a;
      return !!((_a = siteConfig.value.bonuses) == null ? void 0 : _a.isWeb2BonusCashbackWidgetEnabled);
    }),
    isFomoTopBarIconEnabled: computed(() => {
      var _a;
      return !!((_a = siteConfig.value.bonuses) == null ? void 0 : _a.isFomoTopBarIconEnabled);
    }),
    isSeoBetCmsSpintaxEnabled: computed(() => {
      var _a;
      return !!((_a = siteConfig.value.settings) == null ? void 0 : _a.isSeoBetCmsSpintaxEnabled);
    }),
    isMetaBetCmsSpintaxEnabled: computed(() => {
      var _a;
      return !!((_a = siteConfig.value.settings) == null ? void 0 : _a.isMetaBetCmsSpintaxEnabled);
    }),
    isLanguageSelectorFlagsEnabled: computed(() => {
      var _a;
      return !!((_a = siteConfig.value.settings) == null ? void 0 : _a.isLanguageSelectorFlagsEnabled);
    }),
    isPlayTimeControlEnabled: computed(() => {
      var _a;
      return !!((_a = siteConfig.value.settings) == null ? void 0 : _a.isPlayTimeControlEnabled);
    }),
    webSockets: computed(() => siteConfig.value.webSockets),
    payments: computed(() => siteConfig.value.payments),
    millisToSleepAfterCountryChange: computed(() => {
      var _a;
      return ((_a = siteConfig.value.registration) == null ? void 0 : _a.millisToSleepAfterCountryChange) || 0;
    }),
    homePageType: computed(() => {
      var _a;
      return ((_a = siteConfig.value.routing) == null ? void 0 : _a.homePageType) ?? HomePageType.SPORTS;
    }),
    suggestedAmounts: computed(() => {
      var _a;
      return ((_a = siteConfig.value.deposits) == null ? void 0 : _a.suggestedAmounts) ?? [];
    }),
    referralProgramSocialItems: computed(() => {
      var _a, _b;
      return ((_b = (_a = siteConfig.value) == null ? void 0 : _a.profile) == null ? void 0 : _b.referralProgramSocialItems) ?? [];
    }),
    isActivityReporterEnabled: computed(() => {
      var _a, _b;
      return ((_b = (_a = siteConfig.value) == null ? void 0 : _a.activityReporter) == null ? void 0 : _b.activityReporterEnabled) ?? false;
    }),
    doRedirectGuestWithAccountToLogin: computed(
      () => {
        var _a, _b;
        return ((_b = (_a = siteConfig.value) == null ? void 0 : _a.guest) == null ? void 0 : _b.doRedirectGuestWithAccountToLogin) ?? false;
      }
    ),
    redirectToLoginTimeout: computed(
      () => {
        var _a, _b;
        return ((_b = (_a = siteConfig.value) == null ? void 0 : _a.guest) == null ? void 0 : _b.redirectToLoginTimeout) ?? 0;
      }
    ),
    isTempCouponFeatureEnabled: computed(() => {
      var _a, _b;
      return !!((_b = (_a = siteConfig.value) == null ? void 0 : _a.promotions) == null ? void 0 : _b.isTempCouponFeatureEnabled);
    }),
    tempCouponCampaignInterval: computed(() => {
      var _a, _b;
      return ((_b = (_a = siteConfig.value) == null ? void 0 : _a.promotions) == null ? void 0 : _b.tempCouponCampaignInterval) ?? null;
    }),
    festivalCampaignId: computed(() => {
      var _a, _b;
      return ((_b = (_a = siteConfig.value) == null ? void 0 : _a.promotions) == null ? void 0 : _b.festivalCampaignId) ?? null;
    }),
    festivalActionUrl: computed(() => {
      var _a, _b;
      return ((_b = (_a = siteConfig.value) == null ? void 0 : _a.promotions) == null ? void 0 : _b.festivalActionUrl) ?? "";
    }),
    festivalTournamentActionUrls: computed(() => {
      var _a, _b;
      return ((_b = (_a = siteConfig.value) == null ? void 0 : _a.promotions) == null ? void 0 : _b.festivalTournamentActionUrls) ?? [];
    }),
    festivalCouponCampaignId: computed(() => {
      var _a, _b;
      return ((_b = (_a = siteConfig.value) == null ? void 0 : _a.promotions) == null ? void 0 : _b.festivalCouponCampaignId) ?? null;
    }),
    responsibleGamblingV2Enabled: computed(() => {
      var _a;
      return ((_a = siteConfig.value.responsibleGambling) == null ? void 0 : _a.responsibleGamblingV2Enabled) ?? false;
    }),
    selfExclusionOptions: computed(() => {
      var _a;
      return ((_a = siteConfig.value.responsibleGambling) == null ? void 0 : _a.selfExclusionOptions) ?? [];
    }),
    responsibleGamblingRedirectEnabled: computed(() => {
      var _a;
      return ((_a = siteConfig.value.responsibleGambling) == null ? void 0 : _a.responsibleGamblingRedirectEnabled) ?? false;
    }),
    fontFamily: computed(() => {
      var _a;
      return ((_a = siteConfig.value.settings) == null ? void 0 : _a.fontFamily) ?? Web2FontFamily.DEFAULT;
    }),
    settingsBlock: computed(() => siteConfig.value.settings),
    isAccessOptionsEnabled: computed(() => {
      var _a;
      return (_a = accessOptionsBlock.value) == null ? void 0 : _a.areAccessOptionsEnabled;
    }),
    accessOptionsItems: computed(() => {
      var _a;
      return (_a = accessOptionsBlock.value) == null ? void 0 : _a.accessOptionsItems;
    }),
    accessOptionsSocialItems: computed(() => {
      var _a;
      return (_a = accessOptionsBlock.value) == null ? void 0 : _a.accessOptionsSocialItems;
    }),
    isVipFastTrackEnabled: computed(() => {
      var _a;
      return (_a = siteConfig.value.vipFastTrack) == null ? void 0 : _a.isVipFastTrackEnabled;
    }),
    isAchievementEnabled: computed(() => {
      var _a;
      return (_a = siteConfig.value.achievements) == null ? void 0 : _a.isAchievementEnabled;
    }),
    isAvatarsEnabled: computed(() => {
      var _a;
      return (_a = siteConfig.value.achievements) == null ? void 0 : _a.isAvatarsEnabled;
    }),
    sharingConfigs: computed(() => {
      var _a;
      return (_a = siteConfig.value.achievements) == null ? void 0 : _a.sharingConfigs;
    }),
    isRegistrationHintMessageEnabled: computed(() => {
      var _a;
      return (_a = siteConfig.value.registration) == null ? void 0 : _a.isRegistrationHintMessageEnabled;
    }),
    registrationHintMessageLink: computed(() => {
      var _a;
      return (_a = siteConfig.value.registration) == null ? void 0 : _a.registrationHintMessageLink;
    }),
    debugData: computed(() => {
      var _a;
      return (_a = siteConfig.value.debug) == null ? void 0 : _a.debugData;
    }),
    isAccHistoryV2AllTabEnabled: computed(() => {
      var _a;
      return (_a = siteConfig.value.web2AccountHistory) == null ? void 0 : _a.isAccHistoryV2AllTabEnabled;
    }),
    isAccHistoryV2VsportTabEnabled: computed(() => {
      var _a;
      return (_a = siteConfig.value.web2AccountHistory) == null ? void 0 : _a.isAccHistoryV2VsportTabEnabled;
    }),
    isAccHistoryV2SportsbookTabEnabled: computed(() => {
      var _a;
      return (_a = siteConfig.value.web2AccountHistory) == null ? void 0 : _a.isAccHistoryV2SportsbookTabEnabled;
    }),
    isAccHistoryV2EgsTabEnabled: computed(() => {
      var _a;
      return (_a = siteConfig.value.web2AccountHistory) == null ? void 0 : _a.isAccHistoryV2EgsTabEnabled;
    }),
    isAccHistoryV2MarketingTabEnabled: computed(() => {
      var _a;
      return (_a = siteConfig.value.web2AccountHistory) == null ? void 0 : _a.isAccHistoryV2MarketingTabEnabled;
    }),
    isAccHistoryV2PaymentsTabEnabled: computed(() => {
      var _a;
      return (_a = siteConfig.value.web2AccountHistory) == null ? void 0 : _a.isAccHistoryV2PaymentsTabEnabled;
    }),
    bannerSliderDotsCount: computed(() => {
      var _a;
      return (_a = siteConfig.value.bonuses) == null ? void 0 : _a.bannerSliderDotsCount;
    }),
    isEgsTournamentsPromoModalEnabled: computed(() => {
      var _a;
      return !!((_a = siteConfig.value.pushNotificationsWeb) == null ? void 0 : _a.isEgsTournamentsPromoModalEnabled);
    }),
    isFeedbackEnabled: computed(() => {
      var _a;
      return !!((_a = siteConfig.value.feedback) == null ? void 0 : _a.isFeedbackEnabled);
    }),
    feedbackFormCategories: computed(() => {
      var _a;
      return (_a = siteConfig.value.feedback) == null ? void 0 : _a.feedbackFormCategories;
    }),
    rewardCenter: computed(() => siteConfig.value.rewardsCenter),
    // Actions
    updateSiteConfigSettings,
    handleSiteConfigSettings
  };
});
function usePaymentsConfig() {
  const siteConfigStore = useSiteConfigStore();
  const payments = toRef(siteConfigStore, "payments");
  return {
    paymentsListsReloadTimeout: computed(() => {
      var _a;
      return ((_a = payments.value) == null ? void 0 : _a.paymentsListsReloadTimeout) || 3e5;
    }),
    lowBalanceAmounts: computed(() => {
      var _a;
      return ((_a = payments.value) == null ? void 0 : _a.lowBalanceAmounts) ?? [];
    }),
    isInteracEnabled: computed(() => {
      var _a;
      return !!((_a = payments.value) == null ? void 0 : _a.isInteracEnabled);
    }),
    isNoMethodMessageEnabled: computed(() => {
      var _a;
      return !!((_a = payments.value) == null ? void 0 : _a.isNoMethodMessageEnabled);
    }),
    isExpirationValidationDisabled: computed(() => {
      var _a;
      return !!((_a = payments.value) == null ? void 0 : _a.isExpirationValidationDisabled);
    }),
    balanceButtonCurrencies: computed(() => {
      var _a;
      return ((_a = payments.value) == null ? void 0 : _a.balanceButtonCurrencies) ?? [];
    }),
    depositMetricsRequestDelay: computed(() => {
      var _a;
      return ((_a = payments.value) == null ? void 0 : _a.depositMetricsRequestDelay) || 0;
    }),
    isCcTokensEnabled: computed(() => {
      var _a;
      return !!((_a = payments.value) == null ? void 0 : _a.isCcTokensEnabled);
    }),
    isPaymentsMinAmountSuggestionEnabled: computed(() => {
      var _a;
      return !!((_a = payments.value) == null ? void 0 : _a.isPaymentsMinAmountSuggestionEnabled);
    }),
    isPaymentsFastSumEnabled: computed(() => {
      var _a;
      return !!((_a = payments.value) == null ? void 0 : _a.isPaymentsFastSumEnabled);
    }),
    isPaymentsFastSumMaxEnabled: computed(() => {
      var _a;
      return !!((_a = payments.value) == null ? void 0 : _a.isPaymentsFastSumMaxEnabled);
    }),
    paymentsWithdrawalCancelPopupRoutes: computed(() => {
      var _a;
      return ((_a = payments.value) == null ? void 0 : _a.paymentsWithdrawalCancelPopupRoutes) ?? [];
    }),
    paymentsWithdrawalCancelPopupDelay: computed(() => {
      var _a;
      return ((_a = payments.value) == null ? void 0 : _a.paymentsWithdrawalCancelPopupDelay) ?? 0;
    }),
    paymentsWithdrawalCancelPopupInterval: computed(() => {
      var _a;
      return ((_a = payments.value) == null ? void 0 : _a.paymentsWithdrawalCancelPopupInterval) ?? 0;
    }),
    isPaymentsWithdrawalCancelButtonInSuccessModalEnabled: computed(() => {
      var _a;
      return !!((_a = payments.value) == null ? void 0 : _a.isPaymentsWithdrawalCancelButtonInSuccessModalEnabled);
    }),
    zeroBalanceLimit: computed(() => {
      var _a;
      return ((_a = payments.value) == null ? void 0 : _a.zeroBalanceLimit) ?? 0;
    }),
    isPaymentsBonusSelectorV2Enabled: computed(() => {
      var _a;
      return !!((_a = payments.value) == null ? void 0 : _a.isPaymentsBonusSelectorV2Enabled);
    }),
    isPaymentsBonusSelectorOnListEnabled: computed(() => {
      var _a;
      return !!((_a = payments.value) == null ? void 0 : _a.isPaymentsBonusSelectorOnListEnabled);
    }),
    isPaymentsBonusSelectorV2AlternativeLEnabled: computed(() => {
      var _a;
      return !!((_a = payments.value) == null ? void 0 : _a.isPaymentsBonusSelectorV2AlternativeLEnabled);
    }),
    isRequireCityBeforePaymentEnabled: computed(() => {
      var _a;
      return !!((_a = payments.value) == null ? void 0 : _a.isRequireCityBeforePaymentEnabled);
    }),
    paymentsCompanyFeeMoreLink: computed(() => {
      var _a;
      return (_a = payments.value) == null ? void 0 : _a.paymentsCompanyFeeMoreLink;
    }),
    isPaymentsCompanyFeeEnabled: computed(() => {
      var _a;
      return ((_a = payments.value) == null ? void 0 : _a.isPaymentsCompanyFeeEnabled) ?? false;
    })
  };
}
function useWebSocketsConfig() {
  const siteConfigStore = useSiteConfigStore();
  const webSockets = toRef(siteConfigStore, "webSockets");
  return {
    isEnabled: computed(() => {
      var _a;
      return !!((_a = webSockets.value) == null ? void 0 : _a.isEnabled);
    }),
    isBalanceEnabled: computed(() => {
      var _a;
      return !!((_a = webSockets.value) == null ? void 0 : _a.isBalanceEnabled);
    }),
    isCashbackEnabled: computed(() => {
      var _a;
      return !!((_a = webSockets.value) == null ? void 0 : _a.isCashbackEnabled);
    }),
    isLoyaltyUpdateEnabled: computed(() => {
      var _a;
      return !!((_a = webSockets.value) == null ? void 0 : _a.isLoyaltyUpdateEnabled);
    }),
    isCbcBonusWithdrawalAmountEnabled: computed(() => {
      var _a;
      return !!((_a = webSockets.value) == null ? void 0 : _a.isCbcBonusWithdrawalAmountEnabled);
    }),
    isOnDepositEnabled: computed(() => {
      var _a;
      return !!((_a = webSockets.value) == null ? void 0 : _a.isOnDepositEnabled);
    }),
    isSocketStickyBonusStatus: computed(() => {
      var _a;
      return !!((_a = webSockets.value) == null ? void 0 : _a.isSocketStickyBonusStatus);
    }),
    isLoyaltyProgramEnabled: computed(() => {
      var _a;
      return !!((_a = webSockets.value) == null ? void 0 : _a.isLoyaltyProgramEnabled);
    }),
    isWheelNotificationEnabled: computed(() => {
      var _a;
      return !!((_a = webSockets.value) == null ? void 0 : _a.isWheelNotificationEnabled);
    }),
    isFlexibleFreeSpinBetLimitNotificationEnabled: computed(() => {
      var _a;
      return !!((_a = webSockets.value) == null ? void 0 : _a.isFlexibleFreeSpinBetLimitNotificationEnabled);
    }),
    isFastTrackEnabled: computed(() => {
      var _a;
      return !!((_a = webSockets.value) == null ? void 0 : _a.isFastTrackEnabled);
    })
  };
}
export {
  fetchSiteConfigSettings,
  getDefaultCaptchaConfig,
  usePaymentsConfig,
  useSiteConfigStore,
  useWebSocketsConfig
};
