import { assert } from "@leon-hub/guards";
import { RouteName, BaseRouteName } from "@leon-hub/routing-config-names";
import { Events, TargetParamEnter, TargetParamNavigation, getYMInstance } from "@leon-hub/yandex-metrika";
import { GoogleService } from "@leon-hub/metrics";
import { ref, toRef, computed } from "vue";
import { customerBetTypeUndefined, themeDark } from "@leon-hub/api-sdk";
import { getLocationHost, getUserAgent } from "@leon-hub/service-locator-env";
import { defineStore } from "pinia";
import { Locale } from "@leon-hub/locale";
function useAnalytics() {
  function push(event, data) {
    getYMInstance().triggerEvent(event, data);
  }
  function clickMap(payload) {
    push(Events.CLICK_MAP, {
      clickCounter: payload
    });
  }
  return {
    push,
    clickMap,
    phoneCall() {
      push(Events.Z_OPEN_SUPPORT_PAGE, {
        supportDetails: {
          callSupport: {
            clickPhoneNumber: true
          }
        }
      });
    },
    // eslint-disable-next-line sonarjs/cognitive-complexity
    navigationItemClick(navigationItem, isLogin, component = "default") {
      var _a, _b, _c;
      let ServerRouteName;
      ((ServerRouteName2) => {
        ServerRouteName2["AFFILIATE_PROGRAM_WEB2"] = "AFFILIATE_PROGRAM_WEB2";
        ServerRouteName2["DEPOSITS"] = "deposits";
      })(ServerRouteName || (ServerRouteName = {}));
      let ServerNavigationIds;
      ((ServerNavigationIds2) => {
        ServerNavigationIds2["SideBarLeftDeposits"] = "sidebar-left-deposits";
        ServerNavigationIds2["GenericPromotions"] = "generic-promotions";
        ServerNavigationIds2["TopPromotions"] = "top-promotions";
        ServerNavigationIds2["GenericVip"] = "generic-vip";
        ServerNavigationIds2["TopVip"] = "top-vip";
        ServerNavigationIds2["SidebarLeftTabItemLandingVip"] = "sidebar-left-tab-item-landing-vip";
      })(ServerNavigationIds || (ServerNavigationIds = {}));
      let Component;
      ((Component2) => {
        Component2["BaseMenu"] = "BaseMenu";
      })(Component || (Component = {}));
      if (navigationItem.routeName === RouteName.PROMOTIONS && component === String(
        "BaseMenu"
        /* BaseMenu */
      )) {
        clickMap({
          promo: "sideMenuPromo"
        });
      }
      if (navigationItem.routeName === "AFFILIATE_PROGRAM_WEB2" || navigationItem.routeName === BaseRouteName.AFFILIATE_PROGRAM) {
        clickMap({
          affiliateProgramLink: true
        });
      }
      if (navigationItem.routeName === RouteName.DEPOSITS && navigationItem.id === String(
        "sidebar-left-deposits"
        /* SideBarLeftDeposits */
      )) {
        clickMap({
          deposit: "sideMenuDeposit"
        });
      } else if (navigationItem.routeName === RouteName.DEPOSITS) {
        clickMap({
          deposit: "bottomLowBalance"
        });
      }
      if (navigationItem.routeName === RouteName.DEPOSITS && !isLogin && navigationItem.id !== String(
        "sidebar-left-deposits"
        /* SideBarLeftDeposits */
      )) {
        clickMap({
          enter: TargetParamEnter.BOTTOM_LOW_BALANCE
        });
      }
      if (navigationItem.routeName === RouteName.PROFILE && !isLogin) {
        clickMap({
          enter: TargetParamEnter.BOTTOM_LOW_PROFILE
        });
      }
      if (navigationItem.routeName === RouteName.PROMOTIONS && navigationItem.id === String(
        "generic-promotions"
        /* GenericPromotions */
      )) {
        clickMap({
          promo: "footer"
        });
      }
      if (navigationItem.routeName === RouteName.PROMOTIONS && navigationItem.id === String(
        "top-promotions"
        /* TopPromotions */
      )) {
        clickMap({
          promo: "tab"
        });
      }
      if (process.env.VUE_APP_FEATURE_CASINO_ENABLED) {
        switch ((_a = navigationItem.props) == null ? void 0 : _a.metrics) {
          case TargetParamNavigation.BUTTON_LOW_SLOTS:
            clickMap({ SLOTS: TargetParamNavigation.BUTTON_LOW_SLOTS });
            break;
          case TargetParamNavigation.SIDE_MENU_SLOTS:
            clickMap({ SLOTS: TargetParamNavigation.SIDE_MENU_SLOTS });
            break;
          case TargetParamNavigation.HEADER_SLOTS:
            clickMap({ SLOTS: TargetParamNavigation.HEADER_SLOTS });
            break;
        }
      }
      if (process.env.VUE_APP_FEATURE_SPORTLINE_ENABLED) {
        switch ((_b = navigationItem.props) == null ? void 0 : _b.metrics) {
          case TargetParamNavigation.SIDE_MENU_LIVE:
            clickMap({ LIVE: TargetParamNavigation.SIDE_MENU_LIVE });
            break;
          case TargetParamNavigation.HEADER_LIVE:
            clickMap({ LIVE: TargetParamNavigation.HEADER_LIVE });
            break;
        }
      }
      if (process.env.VUE_APP_FEATURE_VIRTUAL_SPORT_ENABLED) {
        switch ((_c = navigationItem.props) == null ? void 0 : _c.metrics) {
          case TargetParamNavigation.SIDE_MENU_VIRTUAL_SPORTS:
            clickMap({ virtualSports: TargetParamNavigation.SIDE_MENU_VIRTUAL_SPORTS });
            break;
          case TargetParamNavigation.HEADER_VIRTUAL_SPORTS:
            clickMap({ virtualSports: TargetParamNavigation.HEADER_VIRTUAL_SPORTS });
            break;
        }
      }
      if (navigationItem.routeName === RouteName.VIP) {
        if (navigationItem.id === String(
          "generic-vip"
          /* GenericVip */
        )) {
          push(Events.Z_CLICK_TO_OPEN_VIP, {
            vip_program: {
              click_to_open: "footer"
            }
          });
        }
        if (navigationItem.id === String(
          "top-vip"
          /* TopVip */
        )) {
          push(Events.Z_CLICK_TO_OPEN_VIP, {
            vip_program: {
              click_to_open: "header"
            }
          });
        }
        if (navigationItem.id === String(
          "sidebar-left-tab-item-landing-vip"
          /* SidebarLeftTabItemLandingVip */
        )) {
          push(Events.Z_CLICK_TO_OPEN_VIP, {
            vip_program: {
              click_to_open: "sidebar"
            }
          });
        }
      }
    },
    yandexZInit(initOptions) {
      const platform = process.env.VUE_APP_PLATFORM;
      assert(platform, "VUE_APP_PLATFORM env must be present");
      push(Events.Z_INIT, {
        userId: initOptions.userId,
        customerType: initOptions.customerType || "normal",
        country: initOptions.country,
        type: {
          [platform]: `${process.env.VUE_APP_LAYOUT}`
        },
        webVersion: process.env.VUE_APP_VERSION,
        ...initOptions.currentTheme ? { mode: initOptions.currentTheme } : {}
      });
    }
  };
}
const useAnalyticsCommonDataStore = /* @__PURE__ */ defineStore("analytics-common-data", () => {
  const customerId = ref("");
  const isLoggedIn = ref(false);
  const isVip = ref(false);
  const country = ref(process.env.VUE_APP_DEFAULT_COUNTRY_CODE ?? "");
  const customerBetType = ref(customerBetTypeUndefined);
  const customerVipStatus = ref(0);
  const currentRouteName = ref("");
  const previousRouteUrl = ref("");
  const previousRouteName = ref("");
  const currentFullUrl = ref("");
  const theme = ref(themeDark);
  const locale = ref(Locale.EN_US);
  function setCustomerId(value) {
    customerId.value = value ?? "";
  }
  function setIsLoggedIn(value) {
    isLoggedIn.value = value ?? false;
  }
  function setIsVip(value) {
    isVip.value = value ?? false;
  }
  function setCountry(value) {
    country.value = value ?? "";
  }
  function setCustomerBetType(value) {
    customerBetType.value = value ?? customerBetTypeUndefined;
  }
  function setCustomerVipStatus(value) {
    customerVipStatus.value = value ?? 0;
  }
  function setCurrentRouteName(value) {
    currentRouteName.value = String(value ?? "");
  }
  function setPreviousRouteUrl(value) {
    previousRouteUrl.value = value ?? "";
  }
  function setPreviousRouteName(value) {
    previousRouteName.value = String(value ?? "");
  }
  function setCurrentFullUrl(value) {
    currentFullUrl.value = value ?? "";
  }
  function setTheme(value) {
    theme.value = value ?? themeDark;
  }
  function setLocale(value) {
    locale.value = value ?? Locale.EN_US;
  }
  return {
    customerId,
    isLoggedIn,
    isVip,
    country,
    customerBetType,
    customerVipStatus,
    currentRouteName,
    previousRouteUrl,
    previousRouteName,
    currentFullUrl,
    theme,
    locale,
    setCustomerId,
    setIsLoggedIn,
    setIsVip,
    setCountry,
    setCustomerBetType,
    setCustomerVipStatus,
    setCurrentRouteName,
    setPreviousRouteUrl,
    setPreviousRouteName,
    setCurrentFullUrl,
    setTheme,
    setLocale
  };
});
function useCommonAnalyticsData() {
  const analyticsStore = useAnalyticsCommonDataStore();
  const customerId = toRef(() => analyticsStore.customerId);
  const isVip = toRef(() => analyticsStore.isVip);
  const customerBetType = toRef(() => analyticsStore.customerBetType);
  const customerVipStatus = toRef(() => analyticsStore.customerVipStatus);
  const country = toRef(() => analyticsStore.country);
  const currentRouteName = toRef(() => analyticsStore.currentRouteName);
  const previousRouteUrl = toRef(() => analyticsStore.previousRouteUrl);
  const previousRouteName = toRef(() => analyticsStore.previousRouteName);
  const currentFullUrl = toRef(() => analyticsStore.currentFullUrl);
  const isLoggedIn = toRef(() => analyticsStore.isLoggedIn);
  const theme = toRef(() => analyticsStore.theme);
  const locale = toRef(() => analyticsStore.locale);
  return {
    commonLogData: computed(() => ({
      customer_id: customerId.value,
      customer_status: isLoggedIn.value ? "logged_in" : "logged_out",
      vs_lw: isVip.value,
      full_url: currentFullUrl.value,
      country: country.value,
      device_type: getUserAgent(),
      platform_type: process.env.VUE_APP_PLATFORM_CORDOVA ? "cordova" : "web",
      theme: theme.value,
      event_timestamp: Date.now(),
      customer_bet_type: customerBetType.value ?? customerBetTypeUndefined,
      customer_type: customerVipStatus.value ?? 0,
      web_version: process.env.VUE_APP_VERSION ?? "",
      previous_page_url: previousRouteUrl.value ?? "",
      main_domain: getLocationHost(),
      lead_section: previousRouteName.value,
      event_category: currentRouteName.value,
      website_product: process.env.VUE_APP_PRODUCT,
      website_skin: process.env.VUE_APP_SKIN,
      website_locale: locale.value
    }))
  };
}
function useGoogleAnalytics() {
  const { commonLogData } = useCommonAnalyticsData();
  function pushGTM(event, data, isResetNeeded) {
    const logData = commonLogData ? { ...commonLogData == null ? void 0 : commonLogData.value, ...data } : data;
    GoogleService.getInstance().pushEvent(event, logData, isResetNeeded);
  }
  return {
    pushGTM
  };
}
var EventActionGoogleAnalytics = /* @__PURE__ */ ((EventActionGoogleAnalytics2) => {
  EventActionGoogleAnalytics2["ABOUT_CLICK"] = "About click";
  EventActionGoogleAnalytics2["ACHIEVEMENTS_CATEGORIES_CLICK"] = "Achievements categories click";
  EventActionGoogleAnalytics2["ACHIEVEMENTS_CARD_CLICK"] = "Achievements card click";
  EventActionGoogleAnalytics2["ACHIEVEMENTS_MODAL_HEADER_CLICK"] = "Achievements modal header click";
  EventActionGoogleAnalytics2["AVATAR_CLICK"] = "Avatar click";
  EventActionGoogleAnalytics2["AVATAR_CONDITIONS_CLICK"] = "Avatar conditions click";
  EventActionGoogleAnalytics2["BACKGROUND_CONDITIONS_CLICK"] = "Background conditions click";
  EventActionGoogleAnalytics2["BACKGROUND_CLICK"] = "Background click";
  EventActionGoogleAnalytics2["CLOSE_CLICK"] = "Close click";
  EventActionGoogleAnalytics2["COPY_ONE_ACHIEVEMENT_CLICK"] = "Copy one achievement click";
  EventActionGoogleAnalytics2["COPY_ALL_ACHIEVEMENTS_CLICK"] = "Copy all achievements click";
  EventActionGoogleAnalytics2["DEPOSIT_LEAD_BUTTON_CLICK"] = "Deposit lead button click";
  EventActionGoogleAnalytics2["EXPRESS_VIP_POPUP_VIEW"] = "Express VIP completed popup view";
  EventActionGoogleAnalytics2["SHARE_ONE_ACHIEVEMENT_CLICK"] = "Share one achievement click";
  EventActionGoogleAnalytics2["SHARE_ALL_ACHIEVEMENTS_CLICK"] = "Share all achievements click";
  EventActionGoogleAnalytics2["SHARED_ONE_ACHIEVEMENT_CLICK"] = "Shared one achievement click";
  EventActionGoogleAnalytics2["SHARED_ALL_ACHIEVEMENTS_CLICK"] = "Shared all achievements click";
  EventActionGoogleAnalytics2["SCROLL_TO_VIP_CLICK"] = "Scroll to express VIP click";
  EventActionGoogleAnalytics2["FILTERS_SETTINGS_CLICK"] = "Filters settings click";
  EventActionGoogleAnalytics2["UNIQUE_ACHIEVEMENT_CLICK"] = "Unique achievement click";
  EventActionGoogleAnalytics2["LOGIN_START_CLICK"] = "Login start click";
  EventActionGoogleAnalytics2["MODAL_POPUP"] = "Modal popup";
  EventActionGoogleAnalytics2["NOTIFICATION_CLICK"] = "Notification click";
  EventActionGoogleAnalytics2["ONBOARDING_BUTTON_CLICK"] = "Onboarding button click";
  EventActionGoogleAnalytics2["ONBOARDING_COMPLETION"] = "Onboarding completion";
  EventActionGoogleAnalytics2["OK_CLICK"] = "Ok click";
  EventActionGoogleAnalytics2["PAGE_VIEW"] = "Page view";
  EventActionGoogleAnalytics2["PAGE_VISIT"] = "Page visit";
  EventActionGoogleAnalytics2["PROFILE_SETTINGS_CLICK"] = "Profile settings click";
  EventActionGoogleAnalytics2["REGISTRATION_START_CLICK"] = "Registration start click";
  EventActionGoogleAnalytics2["REQUEST_CANCELLATION"] = "Request cancellation";
  EventActionGoogleAnalytics2["REWARDS_CENTRE_CARD_CLICK"] = "Rewards centre card click";
  EventActionGoogleAnalytics2["REWARDS_CENTRE_ABOUT_POPUP_VIEW"] = "Rewards centre about popup view";
  EventActionGoogleAnalytics2["REWARDS_LINK_CLICK"] = "Rewards link click";
  EventActionGoogleAnalytics2["SHARE_CLICK"] = "Share click";
  EventActionGoogleAnalytics2["SHARE_CLOSE_CLICK"] = "Share close click";
  EventActionGoogleAnalytics2["SHARE_PRIVACY_CLICK"] = "Share privacy click";
  EventActionGoogleAnalytics2["SHARE_SWIPER_CLICK"] = "Share swiper click";
  EventActionGoogleAnalytics2["SHARED_ACHIEVEMENT_CLICK"] = "Shared achievement click";
  EventActionGoogleAnalytics2["SHARE_ACHIEVEMENT_CARD_MISCLICK"] = "Share achievement card misclick";
  EventActionGoogleAnalytics2["TOURNAMENT_CHECK"] = "Tournament check";
  EventActionGoogleAnalytics2["TOURNAMENT_ENROLLMENT"] = "Tournament enrollment";
  EventActionGoogleAnalytics2["TOURNAMENT_CLOSE_MODAL"] = "Tournament close modal";
  EventActionGoogleAnalytics2["TOURNAMENT_RESULTS_BUTTON_CLICK"] = "Tournament results button click";
  EventActionGoogleAnalytics2["TOURNAMENT_INFO_BUTTON_CLICK"] = "Tournament info button click";
  EventActionGoogleAnalytics2["TOURNAMENT_POPUP_VIEW"] = "Tournament popup view";
  EventActionGoogleAnalytics2["VERIFICATION_INTERRUPTION_POPUP_VIEW"] = "Verification interruption popup view";
  EventActionGoogleAnalytics2["VERIFICATION_INTERRUPTION_CLICK"] = "Verification interruption click";
  EventActionGoogleAnalytics2["VERIFICATION_STATUS_UPDATED"] = "Verification status updated";
  EventActionGoogleAnalytics2["VERIFICATION_START_CLICK"] = "Verification start click";
  EventActionGoogleAnalytics2["VIP_INFO_CLICK"] = "Vip info click";
  EventActionGoogleAnalytics2["WITHDRAWAL_COMMISSION_POPUP_VIEW"] = "Withdrawal commission popup view";
  EventActionGoogleAnalytics2["WITHDRAWAL_COMMISSION_ACCEPTED_BUTTON_CLICKS"] = "Withdrawal commission accepted button clicks";
  EventActionGoogleAnalytics2["WITHDRAWAL_COMMISSION_DO_NOT_SHOW_BUTTON_CLICKS"] = "Withdrawal commission do not show button clicks";
  EventActionGoogleAnalytics2["WITHDRAWAL_CANCEL_BUTTON_CLICKS"] = "Withdrawal cancel button clicks";
  EventActionGoogleAnalytics2["WITHDRAWAL_COMMISSION_MORE_CLICKS"] = "Withdrawal commission more clicks";
  return EventActionGoogleAnalytics2;
})(EventActionGoogleAnalytics || {});
export {
  EventActionGoogleAnalytics,
  useAnalytics,
  useAnalyticsCommonDataStore,
  useGoogleAnalytics
};
