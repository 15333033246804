import { GqlBatchedClient, ApiConnectionError, ApiRequestAbortedError, RestClient, getBaseHeaders, resolveApi2Url } from "@leon-hub/api";
import { logger } from "@leon-hub/logging";
import { assert, isNumber, isObject, isString } from "@leon-hub/guards";
import { QueryParameterName } from "@leon-hub/query-manager";
import { getQueryParams, getBuiltinConfig } from "@leon-hub/service-locator-env";
class ApplicationGqlClient extends GqlBatchedClient {
  async requestGraphql(query, { options }, callback, requestOptions, ref) {
    try {
      return await super.requestGraphql(query, { options }, callback, requestOptions, ref);
    } catch (error) {
      if ((error instanceof ApiConnectionError || error instanceof ApiRequestAbortedError) && !error.silent && this.connectionErrorResolver) {
        try {
          logger.debug(`Connection error GraphQL: ${query}`);
          await this.connectionErrorResolver(error);
          return await this.requestGraphql(query, { options }, callback, requestOptions, ref);
        } catch {
        }
      }
      throw error;
    }
  }
}
class ApplicationRestClient extends RestClient {
  constructor(options) {
    super({
      ...options,
      origin: options.origin ?? (process.env.VUE_APP_PRERENDER ? "" : void 0)
    });
  }
  async request(options) {
    try {
      return await super.request(options);
    } catch (error) {
      if (this.connectionErrorResolver && error instanceof ApiConnectionError && !error.silent) {
        try {
          logger.debug(`Connection error Rest client: ${options.endpoint}`);
          await this.connectionErrorResolver(error);
          return await this.request(options);
        } catch {
        }
      }
      throw error;
    }
  }
}
class DefaultRestApiClient extends ApplicationRestClient {
  constructor() {
    super({
      method: "GET",
      baseUrl: resolveApi2Url(),
      headers: getBaseHeaders()
    });
  }
}
let baseApiClient;
function useBaseApiClient(callback) {
  if (callback) {
    baseApiClient = callback();
    return void 0;
  }
  assert(baseApiClient, "baseApiClient is not initialized");
  return baseApiClient;
}
let canUseConfig = !/* @__PURE__ */ (() => process.env.VUE_APP_PLATFORM_CORDOVA)();
const initialTimeExpiry = 1e4;
function createCoreApi(client) {
  return {
    async loadTranslations(i18nUrl) {
      return client.request({
        id: "i18n/translations",
        endpoint: i18nUrl,
        baseUrl: "",
        guard: (value) => isObject(value) && Object.values(value).every(isString),
        silent: true
      });
    },
    async syncServerTime() {
      if (getQueryParams()[QueryParameterName.TEST_CASE] === "performance") {
        return Date.now();
      }
      if (canUseConfig) {
        const { docParseTime, time } = getBuiltinConfig();
        if (time && docParseTime) {
          const now = Date.now();
          if (time > now - initialTimeExpiry) {
            const timeFromParseToNow = now - docParseTime;
            return time + timeFromParseToNow;
          }
        }
        canUseConfig = false;
      }
      return client.request({
        id: "time/sync",
        endpoint: "/time",
        guard: isNumber,
        silent: true
      });
    }
  };
}
function useCoreApi() {
  return createCoreApi(useBaseApiClient());
}
let gqlClient;
function useGraphqlClient(callback) {
  if (callback) {
    gqlClient = callback();
    return void 0;
  }
  assert(gqlClient, "gqlClient is not initialized");
  return gqlClient;
}
function setApiHeaders(value) {
  const gqlApi = useGraphqlClient();
  const restClient = useBaseApiClient();
  gqlApi.setCustomHeaders(value);
  restClient.setCustomHeaders(value);
}
export {
  ApplicationGqlClient,
  ApplicationRestClient,
  DefaultRestApiClient,
  setApiHeaders,
  useBaseApiClient,
  useCoreApi,
  useGraphqlClient
};
