import { ref, watch, nextTick } from "vue";
import { defineStatefulComposable } from "@leon-hub/vue-utils";
import { defineStore } from "pinia";
import { useAnalyticsCommonDataStore } from "@core/analytics";
const useIsLoggedIn = /* @__PURE__ */ defineStatefulComposable(() => {
  const isLoggedIn = ref(false);
  return {
    isLoggedIn
  };
});
const IS_CLEAR_PASSWORD_KEY = "isClearPasswordOnLogin";
const useAuthStore = /* @__PURE__ */ defineStore("auth", () => {
  const { isLoggedIn } = useIsLoggedIn();
  const isTemporarySession = ref(false);
  const isLoadedFromRoot = ref(false);
  function setLoggedIn(value, fromRoot = false) {
    isLoggedIn.value = value;
    void nextTick().then(() => {
      if (fromRoot) {
        isLoadedFromRoot.value = true;
      }
    });
  }
  function setIsTemporarySession(value) {
    isTemporarySession.value = value;
  }
  function forgotSession() {
    if (process.env.VUE_APP_PLATFORM_CORDOVA) {
      localStorage.setItem(IS_CLEAR_PASSWORD_KEY, "true");
    }
    isLoggedIn.value = false;
  }
  const analyticsStore = useAnalyticsCommonDataStore();
  watch(isLoggedIn, (newValue) => {
    analyticsStore.setIsLoggedIn(newValue);
  }, { immediate: true });
  return {
    isLoggedIn,
    isLoadedFromRoot,
    isTemporarySession,
    setLoggedIn,
    forgotSession,
    setIsTemporarySession
  };
});
export {
  IS_CLEAR_PASSWORD_KEY,
  useAuthStore,
  useIsLoggedIn
};
