import { whenClientNetworkIdle } from "@leon-hub/idle";
import { ScriptElement } from "@leon-hub/utils";
import { zetaDcpConfigTypeDeposit, zetaDcpConfigTypeFirstTimeDeposit, zetaDcpConfigTypeRegistration, zetaDcpConfigTypeAll } from "@leon-hub/api-sdk";
import { logger as logger$1 } from "@leon-hub/logging";
class GoogleService {
  constructor() {
    this.isInitiated = false;
    window.dataLayer = window.dataLayer || [];
  }
  async initGoogle(id = "") {
    await whenClientNetworkIdle({ interval: 300 });
    const gtmContent = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${id}');`;
    await new ScriptElement({
      content: gtmContent,
      name: "gtm0",
      appendTarget: "head"
    }).addScript();
  }
  static getInstance() {
    if (!GoogleService.instance) {
      GoogleService.instance = new GoogleService();
    }
    return GoogleService.instance;
  }
  setCounterId(counterId) {
    const isNotConfigured = !!counterId && !this.isInitiated;
    if (isNotConfigured) {
      void this.initGoogle(counterId);
      this.isInitiated = true;
    }
  }
  pushEvent(eventName, data, isResetNeeded) {
    var _a, _b;
    if (isResetNeeded) {
      (_a = window.dataLayer) == null ? void 0 : _a.push(function() {
        this.reset();
      });
    }
    (_b = window.dataLayer) == null ? void 0 : _b.push({ event: eventName, ...data });
  }
}
const logger = /* @__PURE__ */ logger$1.createNamespace("ZetaDspService");
class ZetaDspService {
  constructor() {
    this.zetaDSPDefaultOptions = {};
  }
  setDefaultOptions(defaultOptions) {
    this.zetaDSPDefaultOptions = defaultOptions;
  }
  static getInstance() {
    if (!ZetaDspService.instance) {
      ZetaDspService.instance = new ZetaDspService();
    }
    return ZetaDspService.instance;
  }
  sendDepositParams(metrics, initArguments) {
    if (initArguments) {
      this.setArgsAndTrack({
        cur: "usd",
        leadid: metrics.userId,
        price: metrics.systemAmount,
        revenue: metrics.systemAmount,
        transid: metrics.paymentId,
        ...initArguments
      });
    }
  }
  makeDeposit(metrics) {
    this.sendDepositParams(metrics, this.zetaDSPDefaultOptions[zetaDcpConfigTypeDeposit]);
  }
  firstDeposit(metrics) {
    this.sendDepositParams(metrics, this.zetaDSPDefaultOptions[zetaDcpConfigTypeFirstTimeDeposit]);
  }
  customerRegistered(parameters) {
    this.setArgsAndTrack({
      ...this.zetaDSPDefaultOptions[zetaDcpConfigTypeRegistration],
      ...parameters
    });
  }
  setArgsAndTrack(extraArguments) {
    if (!this.zetaDSPDefaultOptions) {
      logger.error("ZetaDspService is trying to send metrics without default options", extraArguments);
      return;
    }
    const allArguments = { ...this.zetaDSPDefaultOptions[zetaDcpConfigTypeAll], ...extraArguments };
    if (typeof window._rfi !== "function") {
      window._rfi = function rfi() {
        window._rfi.commands = window._rfi.commands || [];
        window._rfi.commands.push(arguments);
      };
    }
    const argumentsKey = Object.keys(allArguments);
    for (const key of argumentsKey) {
      window._rfi("setArgs", key, allArguments[key]);
    }
    window._rfi("track");
  }
}
export {
  GoogleService,
  ZetaDspService
};
