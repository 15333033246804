import { defineStore, storeToRefs } from "pinia";
import { ref, computed, watch, toRef, unref } from "vue";
import { getInitLocale, isBootstrapTranslationKey, getBootstrapTranslations } from "@leon-hub/bootstrap-translations";
import { isDebugEnabled } from "@leon-hub/debug";
import { useLocalStorageManager } from "@leon-hub/local-storage";
import { Locale, localeStorageKey, LanguageMicroSettings } from "@leon-hub/locale";
import { Timer, createDateLocalizers, ruPatternLocaleDateTime } from "@leon-hub/utils";
import { useAnalyticsCommonDataStore } from "@core/analytics";
import { useSyncState } from "@core/sync-state";
import { doChangeLanguage as doChangeLanguage$1, getTranslations } from "@leon-hub/api-sdk";
import { useGraphqlClient, setApiHeaders } from "@core/app-rest-client";
import memoize from "lodash/memoize";
import { getNewUri } from "@core/router";
const EMPTY_PLACEHOLDER = "_____";
const DYNAMIC_KEYS = [];
const REFRESHED_AFTER_LOGIN = "refreshedAfterLogin";
const primaryKeys = /* @__PURE__ */ (() => [
  ...__I18N_KEYS__,
  ...DYNAMIC_KEYS
])();
function doChangeLanguage(languageTag) {
  const apiClient = useGraphqlClient();
  return doChangeLanguage$1(apiClient, (node) => node.mutations.system.changeLanguage, {
    options: {
      languageTag
    }
  });
}
async function fetchTranslations(keys, silent) {
  const apiClient = useGraphqlClient();
  const response = await getTranslations(
    apiClient,
    (node) => node.queries.system.getTranslationBundleByKeys,
    {
      options: {
        keys,
        mobile: true
      }
    },
    {
      silent
    }
  );
  return response.translations;
}
function setLanguageHeader(value) {
  setApiHeaders({ "x-app-language": value });
}
function processParameters(translate, parameters) {
  return Object.keys(parameters).reduce((accumulator, item) => accumulator.replace(new RegExp(`{${item}}`, "g"), parameters[item]), translate);
}
const useI18nStore = /* @__PURE__ */ defineStore("i18n", () => {
  const languageSelectorPlace = ref("");
  const localStorageManager = useLocalStorageManager();
  const translations = {};
  const locale = ref(
    localStorageManager.getItem(localeStorageKey) || getInitLocale() || Locale.RU_RU
  );
  const currentUrlPrefix = ref("");
  const replacedUrlPrefix = ref("");
  const lang = computed(() => locale.value.split("_")[0]);
  async function fetchKeys(keys, silent = false) {
    updateKeKeys(await fetchTranslations(keys, silent));
  }
  function updateKeKeys(items) {
    if (items.length > 0) {
      for (const translation of items) {
        if (!translations[translation.key]) {
          translations[translation.key] = ref(translation.value);
        } else {
          translations[translation.key].value = translation.value;
        }
      }
    }
  }
  function loadPrimaryKeys(silent = false) {
    return fetchKeys(primaryKeys, silent);
  }
  let pendingTranslations = [];
  let pendingFetchTimeoutId = 0;
  function clearPendingFetchTimeout() {
    if (pendingFetchTimeoutId) {
      Timer.clearTimeout(pendingFetchTimeoutId);
      pendingFetchTimeoutId = 0;
    }
  }
  function addPendingKey(key) {
    if (primaryKeys.includes(key)) {
      return;
    }
    pendingTranslations.push(key);
    clearPendingFetchTimeout();
    pendingFetchTimeoutId = Timer.setTimeout(() => {
      const keys = [...pendingTranslations];
      void fetchKeys(keys, true).then(() => {
        pendingTranslations = pendingTranslations.filter((item) => !keys.includes(item));
      });
    }, 40);
  }
  function $t(key, parameters) {
    if (!translations[key]) {
      const emptyPlaceholder = isDebugEnabled() ? key : EMPTY_PLACEHOLDER;
      const defaultTranslationValue = isBootstrapTranslationKey(key) ? getBootstrapTranslations()[key] || emptyPlaceholder : emptyPlaceholder;
      translations[key] = ref(defaultTranslationValue);
      addPendingKey(key);
    }
    if (!parameters) {
      return translations[key];
    }
    return computed(() => processParameters(translations[key].value, parameters.value));
  }
  function getTranslationFunction() {
    return (key, parameters) => $t(key, parameters ? ref(parameters) : void 0).value;
  }
  function setLocale(value) {
    locale.value = value;
    setLanguageHeader(value);
    localStorageManager.setItem(localeStorageKey, value);
  }
  async function changeLanguage(languageTag) {
    const result = await doChangeLanguage(languageTag);
    if (result.currentLanguageTag) {
      setLocale(result.currentLanguageTag);
      currentUrlPrefix.value = result.urlLocale || "";
    }
  }
  function setUrlPrefixes({
    currentPrefix,
    replacedPrefix
  }) {
    currentUrlPrefix.value = currentPrefix;
    replacedUrlPrefix.value = replacedPrefix;
  }
  function setLanguageSelectorPlace(value) {
    languageSelectorPlace.value = value;
  }
  useSyncState((silent) => loadPrimaryKeys(silent), "i18n");
  const analyticsStore = useAnalyticsCommonDataStore();
  watch(locale, (newValue) => {
    analyticsStore.setLocale(newValue);
  }, { immediate: true });
  return {
    locale,
    lang,
    currentUrlPrefix,
    replacedUrlPrefix,
    updateKeKeys,
    loadPrimaryKeys,
    $t,
    getTranslationFunction,
    setLocale,
    setUrlPrefixes,
    changeLanguage,
    setLanguageSelectorPlace,
    languageSelectorPlace
  };
});
const localeDateTimeSpecMonthFullNames = [
  "JANUARY",
  "FEBRUARY",
  "MARCH",
  "APRIL",
  "MAY",
  "JUNE",
  "JULY",
  "AUGUST",
  "SEPTEMBER",
  "OCTOBER",
  "NOVEMBER",
  "DECEMBER"
];
const localeDateTimeSpecMonthShortNames = [
  "JAN",
  "FEB",
  "MAR",
  "APR",
  "MAY",
  "JUN",
  "JUL",
  "AUG",
  "SEP",
  "OCT",
  "NOV",
  "DEC"
];
function getMonthFullNames($t) {
  return [
    process.env.NODE_ENV === "test" ? ref(localeDateTimeSpecMonthFullNames[0]) : $t("WEB2_MONTH_JANUARY_GENITIVE"),
    process.env.NODE_ENV === "test" ? ref(localeDateTimeSpecMonthFullNames[1]) : $t("WEB2_MONTH_FEBRUARY_GENITIVE"),
    process.env.NODE_ENV === "test" ? ref(localeDateTimeSpecMonthFullNames[2]) : $t("WEB2_MONTH_MARCH_GENITIVE"),
    process.env.NODE_ENV === "test" ? ref(localeDateTimeSpecMonthFullNames[3]) : $t("WEB2_MONTH_APRIL_GENITIVE"),
    process.env.NODE_ENV === "test" ? ref(localeDateTimeSpecMonthFullNames[4]) : $t("WEB2_MONTH_MAY_GENITIVE"),
    process.env.NODE_ENV === "test" ? ref(localeDateTimeSpecMonthFullNames[5]) : $t("WEB2_MONTH_JUNE_GENITIVE"),
    process.env.NODE_ENV === "test" ? ref(localeDateTimeSpecMonthFullNames[6]) : $t("WEB2_MONTH_JULY_GENITIVE"),
    process.env.NODE_ENV === "test" ? ref(localeDateTimeSpecMonthFullNames[7]) : $t("WEB2_MONTH_AUGUST_GENITIVE"),
    process.env.NODE_ENV === "test" ? ref(localeDateTimeSpecMonthFullNames[8]) : $t("WEB2_MONTH_SEPTEMBER_GENITIVE"),
    process.env.NODE_ENV === "test" ? ref(localeDateTimeSpecMonthFullNames[9]) : $t("WEB2_MONTH_OCTOBER_GENITIVE"),
    process.env.NODE_ENV === "test" ? ref(localeDateTimeSpecMonthFullNames[10]) : $t("WEB2_MONTH_NOVEMBER_GENITIVE"),
    process.env.NODE_ENV === "test" ? ref(localeDateTimeSpecMonthFullNames[11]) : $t("WEB2_MONTH_DECEMBER_GENITIVE")
  ];
}
function getMonthShortNames($t) {
  return [
    process.env.NODE_ENV === "test" ? ref(localeDateTimeSpecMonthShortNames[0]) : $t("WEB2_MONTH_JANUARY_SHORT"),
    process.env.NODE_ENV === "test" ? ref(localeDateTimeSpecMonthShortNames[0]) : $t("WEB2_MONTH_FEBRUARY_SHORT"),
    process.env.NODE_ENV === "test" ? ref(localeDateTimeSpecMonthShortNames[0]) : $t("WEB2_MONTH_MARCH_SHORT"),
    process.env.NODE_ENV === "test" ? ref(localeDateTimeSpecMonthShortNames[0]) : $t("WEB2_MONTH_APRIL_SHORT"),
    process.env.NODE_ENV === "test" ? ref(localeDateTimeSpecMonthShortNames[0]) : $t("WEB2_MONTH_MAY_SHORT"),
    process.env.NODE_ENV === "test" ? ref(localeDateTimeSpecMonthShortNames[0]) : $t("WEB2_MONTH_JUNE_SHORT"),
    process.env.NODE_ENV === "test" ? ref(localeDateTimeSpecMonthShortNames[0]) : $t("WEB2_MONTH_JULY_SHORT"),
    process.env.NODE_ENV === "test" ? ref(localeDateTimeSpecMonthShortNames[0]) : $t("WEB2_MONTH_AUGUST_SHORT"),
    process.env.NODE_ENV === "test" ? ref(localeDateTimeSpecMonthShortNames[0]) : $t("WEB2_MONTH_SEPTEMBER_SHORT"),
    process.env.NODE_ENV === "test" ? ref(localeDateTimeSpecMonthShortNames[0]) : $t("WEB2_MONTH_OCTOBER_SHORT"),
    process.env.NODE_ENV === "test" ? ref(localeDateTimeSpecMonthShortNames[0]) : $t("WEB2_MONTH_NOVEMBER_SHORT"),
    process.env.NODE_ENV === "test" ? ref(localeDateTimeSpecMonthShortNames[0]) : $t("WEB2_MONTH_DECEMBER_SHORT")
  ];
}
const cache = /* @__PURE__ */ new Map();
const getDateLocalizers = /* @__PURE__ */ memoize((locale, $t) => {
  let localizers = cache.get(locale);
  if (!localizers) {
    const shortMonthNames = getMonthShortNames($t);
    const fullMonthNames = getMonthFullNames($t);
    const getMonth = ({ id, format }) => {
      const list = format === "short" ? shortMonthNames : fullMonthNames;
      if (id in list) {
        return list[id].value;
      }
      throw new RangeError(`Missing month number: ${id}`);
    };
    localizers = createDateLocalizers({ pattern: ruPatternLocaleDateTime, getMonth });
    cache.set(locale, localizers);
  }
  return localizers;
}, (locale) => locale);
function useDateLocalizers() {
  const { locale, $t } = useI18nStore();
  return getDateLocalizers(locale, $t);
}
const useI18n = /* @__PURE__ */ memoize(() => {
  return {
    get $translate() {
      return useI18nStore().$t;
    }
  };
});
const useDynamicTranslation = function() {
  const { $translate } = useI18n();
  const translations = {
    WEB2_CORDOVA_THEME_$: {
      AUTO: $translate("WEB2_CORDOVA_THEME_AUTO").value,
      DARK: $translate("WEB2_CORDOVA_THEME_DARK").value,
      LIGHT: $translate("WEB2_CORDOVA_THEME_LIGHT").value
    },
    WEB2_UPLOAD_FILE_ERROR_$: {
      ERROR: $translate("WEB2_UPLOAD_FILE_ERROR_ERROR").value,
      SIZE_LIMIT: $translate("WEB2_UPLOAD_FILE_ERROR_SIZE_LIMIT").value,
      MAX_FILES_LIMIT: $translate("WEB2_UPLOAD_FILE_ERROR_MAX_FILES_LIMIT").value,
      INVALID_FORMAT_SIMPLEID: $translate("WEB2_UPLOAD_FILE_ERROR_INVALID_FORMAT_SIMPLEID").value,
      TERMINATE: $translate("WEB2_UPLOAD_FILE_ERROR_TERMINATE").value,
      FILES_LIMIT_AT_ONCE: $translate("WEB2_UPLOAD_FILE_ERROR_FILES_LIMIT_AT_ONCE").value
    }
  };
  return (template, key) => {
    const namespace = translations[template];
    const value = namespace[key];
    if (!value) {
      console.warn(`[useDynamicTranslation] translation for ${template} + ${key} is not found`);
      return EMPTY_PLACEHOLDER;
    }
    return value;
  };
};
function reloadPageWithCurrentLang(replaced, current) {
  if (process.env.VUE_APP_PLATFORM_CORDOVA) {
    useLocalStorageManager().setItem(REFRESHED_AFTER_LOGIN, "1");
    window.location.reload();
  } else {
    window.location.href = getNewUri(replaced, current);
  }
}
function useI18nLanguage() {
  const i18nStore = useI18nStore();
  const { setLanguageSelectorPlace } = i18nStore;
  function isLanguageChangeable(languageTag) {
    if (!languageTag)
      return false;
    const userLanguageTag = languageTag.replace("-", "_");
    return i18nStore.locale !== userLanguageTag;
  }
  async function setLanguageTag({
    reload,
    languageTag,
    onChangeCallback
  }) {
    if (!isLanguageChangeable(languageTag))
      return;
    const prevUrlPrefix = i18nStore.currentUrlPrefix;
    await i18nStore.changeLanguage(languageTag);
    onChangeCallback == null ? void 0 : onChangeCallback({
      languageSelectorPlace: i18nStore.languageSelectorPlace
    });
    if (reload) {
      reloadPageWithCurrentLang(prevUrlPrefix, i18nStore.currentUrlPrefix);
    }
  }
  return {
    setLanguageTag,
    setLanguageSelectorPlace,
    isLanguageChangeable
  };
}
function useI18nLocale() {
  const store = useI18nStore();
  return {
    locale: toRef(store, "locale"),
    lang: toRef(store, "lang")
  };
}
function useLocalizedTimeString() {
  const i18nStore = useI18nStore();
  const { $t } = i18nStore;
  function getTimeString(h, m) {
    return {
      hours: LanguageMicroSettings.plural([
        $t("WEB2_PLURAL_HOURS_ONE_GENITIVE", h).value,
        $t("WEB2_PLURAL_HOURS_TWO", h).value,
        $t("WEB2_PLURAL_HOURS_MANY", h).value
      ], Number(h.value.count), i18nStore.locale),
      minutes: LanguageMicroSettings.plural([
        $t("WEB2_PLURAL_MINUTES_ONE_GENITIVE", m).value,
        $t("WEB2_PLURAL_MINUTES_TWO", m).value,
        $t("WEB2_PLURAL_MINUTES_MANY", m).value
      ], Number(m.value.count), i18nStore.locale)
    };
  }
  return {
    getTimeString
  };
}
const I18nPlugin = {
  install(app) {
    const i18nStore = useI18nStore();
    const { lang, locale } = storeToRefs(i18nStore);
    const { getTranslationFunction } = i18nStore;
    app.config.globalProperties.$t = getTranslationFunction();
    Object.defineProperty(app.config.globalProperties, "$lang", {
      enumerable: true,
      get: () => unref(lang)
    });
    Object.defineProperty(app.config.globalProperties, "$locale", {
      enumerable: true,
      get: () => unref(locale)
    });
  }
};
export {
  I18nPlugin,
  REFRESHED_AFTER_LOGIN,
  fetchTranslations,
  primaryKeys,
  useDateLocalizers,
  useDynamicTranslation,
  useI18n,
  useI18nLanguage,
  useI18nLocale,
  useI18nStore,
  useLocalizedTimeString
};
