import { onComponentActivated, onComponentDeactivated } from "@leon-hub/vue-utils";
import { ref, toRef, computed, shallowRef, watch, triggerRef } from "vue";
import { isString } from "@leon-hub/guards";
import { Timer } from "@leon-hub/utils";
import { defineStore } from "pinia";
import { useAuthStore, useIsLoggedIn } from "@core/auth";
const useModuleTimersStore = /* @__PURE__ */ defineStore("module-timers", () => {
  const defaultTimeout = ref(6e4);
  const moduleTimeouts = ref({});
  function setDefaultTimeout(value) {
    defaultTimeout.value = value;
  }
  function setModuleTimeouts(value) {
    moduleTimeouts.value = value;
  }
  return {
    defaultTimeout,
    moduleTimeouts,
    setDefaultTimeout,
    setModuleTimeouts
  };
});
function useModuleTimeout(timerName, timerFallback) {
  const moduleTimersStore = useModuleTimersStore();
  const defaultTimeout = toRef(moduleTimersStore, "defaultTimeout");
  const moduleTimeouts = toRef(moduleTimersStore, "moduleTimeouts");
  const timeoutSettings = computed(() => timerName ? moduleTimeouts.value[timerName] : void 0);
  const timeout = computed(() => timeoutSettings.value ?? (timerFallback == null ? void 0 : timerFallback.value) ?? defaultTimeout.value);
  return {
    timeout
  };
}
const useModulesSyncStore = /* @__PURE__ */ defineStore("modules-sync", () => {
  const authStore = useAuthStore();
  const isLoadedFromRoot = toRef(() => authStore.isLoadedFromRoot);
  const { isLoggedIn } = useIsLoggedIn();
  const syncStateTriggerRef = shallowRef(true);
  function forceSyncStateAll() {
    triggerRef(syncStateTriggerRef);
  }
  function watchGlobalSyncState(callback) {
    return watch(syncStateTriggerRef, callback);
  }
  function watchLoggedInSyncState(callback) {
    return watch(isLoggedIn, () => {
      if (!isLoadedFromRoot.value) {
        return;
      }
      void callback();
    });
  }
  return {
    forceSyncStateAll,
    watchGlobalSyncState,
    watchLoggedInSyncState
  };
});
function useSyncState(callback, timerName, {
  suspendedByDefault,
  disableLoginLogoutHandlers,
  condition
} = {}) {
  if (!timerName) {
    throw new Error("Please provide timerName to use syncState");
  }
  if (process.env.NODE_ENV === "test" && !process.env.IS_SYNC_STATE_TEST) {
    return {
      pauseSyncState() {
      },
      resumeSyncState() {
      },
      timeout: ref(0),
      isSyncAvailable: ref(false)
    };
  }
  const { watchGlobalSyncState, watchLoggedInSyncState } = useModulesSyncStore();
  const { timeout } = isString(timerName) ? useModuleTimeout(timerName) : useModuleTimeout(null, timerName);
  const isStopped = ref(suspendedByDefault ?? false);
  const conditionValue = condition ?? ref(true);
  const isSyncAvailable = computed(() => !isStopped.value && !!conditionValue.value && timeout.value > 0);
  let timerId = 0;
  function stopSyncState() {
    if (timerId) {
      Timer.clearTimeout(timerId);
      timerId = 0;
    }
  }
  function startSyncState() {
    stopSyncState();
    if (isSyncAvailable.value) {
      timerId = Timer.setTimeout(() => {
        void callback(true).finally(() => {
          startSyncState();
        });
      }, timeout.value);
    }
  }
  function pauseSyncState() {
    if (!isStopped.value) {
      isStopped.value = true;
      stopSyncState();
    }
  }
  function resumeSyncState() {
    if (isStopped.value) {
      isStopped.value = false;
      startSyncState();
    }
  }
  watch(timeout, startSyncState);
  watch(isSyncAvailable, (newValue) => {
    if (newValue) {
      startSyncState();
    } else {
      stopSyncState();
    }
  }, { immediate: true });
  watchGlobalSyncState(() => {
    void callback(true);
  });
  if (!disableLoginLogoutHandlers) {
    watchLoggedInSyncState(() => {
      void callback();
    });
  }
  return {
    timeout,
    isSyncAvailable,
    pauseSyncState,
    resumeSyncState
  };
}
function useComponentSyncState(callback, timerName, options) {
  const {
    timeout,
    pauseSyncState,
    resumeSyncState
  } = useSyncState(callback, timerName, options);
  onComponentActivated(resumeSyncState);
  onComponentDeactivated(pauseSyncState);
  return {
    timeout
  };
}
function useForceSyncStateAll() {
  const { forceSyncStateAll } = useModulesSyncStore();
  return { forceSyncStateAll };
}
function usePauseSyncStateReasons() {
  const reasons = ref([]);
  const isPaused = computed(() => reasons.value.length > 0);
  function setPauseState(reason, value) {
    reasons.value = value ? [...reasons.value, reason] : [...reasons.value].filter((existReason) => existReason !== reason);
  }
  return {
    reasons,
    isPaused,
    setPauseState
  };
}
export {
  useComponentSyncState,
  useForceSyncStateAll,
  useModuleTimeout,
  useModuleTimersStore,
  usePauseSyncStateReasons,
  useSyncState
};
